@use 'sass:math';
@import '../colors.scss';
@import '../mixins.scss';

$spinnerSize: 40;

.link {
  &, * {
    cursor: pointer;
  }
}

.depooler {
  background-color: $SURF_GRAY_BACKGROUND_2;

  footer {
    background-color: $SURF_GRAY_BACKGROUND_2;
  }

  .filter-container {
    padding-bottom: 2.5rem;

    .depooler-filters {
      display: flex;
      flex-direction: row;

      .dropdown-toggle {
        margin-right: .5rem;
        
        &:after {
          content: none;
        }
        svg {
          margin-right: .5rem;
          transform: translateY(-1px);
        }
      }
      .dropdown-menu {
        transform: none !important;
        background-color: $surf-gray;
        color: $white;
        margin-top: 0;
        a {
          &, &.active {
            background-color: $surf-gray;
            color: $white;
          }
        }
        .dropdown-item {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
        }
      }
    }
  }

  .content-container {
    overflow: hidden;
  }
  .table-container, .filter-container {
    overflow: visible;
  }

  main {
    padding-top: 65px;
    padding-bottom: 160px;
    background-color: $SURF_GRAY_BACKGROUND_2;
    
    @media (max-width: 575px) {
      padding-top: 10px;
    }

    .page-title {
      padding-top: 20px;

      @media (max-width: 575px) {
        font-size: 2rem;
        line-height: 2.5rem;
      }
    }

    .page-description {
      font-size: 1.0625rem;
      margin-top: 1.125rem;
      margin-bottom: 80px;
      max-width: 400px;
    }
    .pulse-overview {
      padding-top: 0.5rem;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .title-track {
      padding-top: 50px;
      font-size: 1.875rem;
    }
  }

  .table {
    display: table;
    animation: fadeIn 1s cubic-bezier(0.375, 0.885, 0.6, 1);
    -webkit-animation: fadeIn 1s cubic-bezier(0.375, 0.885, 0.6, 1);
    -moz-animation: fadeIn 1s cubic-bezier(0.375, 0.885, 0.6, 1);
    -o-animation: fadeIn 1s cubic-bezier(0.375, 0.885, 0.6, 1);
    -ms-animation: fadeIn 1s cubic-bezier(0.375, 0.885, 0.6, 1);
    position: static;
    margin-left: 1.25rem;
    margin-right: 1.25rem;
    width: auto;


    .tr {
      display: table-row;

      .th, .td {
        display: table-cell;
        font-weight: 400;
        border: none;

        &:first-child {
          width: 2.5rem;
        }

        &:nth-child(1), &:nth-child(2), &:nth-last-child(1), &:nth-last-child(2) {
          display: table-cell;
        }

        &:nth-child(2) {
          min-width: 25px;
        }
        &:nth-child(2) {
          min-width: 120px;
        }
        &:nth-child(3) {
          @media (max-width: 480px) {
            display: none;
          }
        }
        &:nth-child(4) {
          @media (max-width: 550px) {
            display: none;
          }
        }
        &:nth-child(5) {
          @media (max-width: 640px) {
            display: none;
          }
        }
        &:nth-child(6) {
          @media (max-width: 750px) {
            display: none;
          }
        }
        &:nth-child(7) {
          @media (max-width: 830px) {
            display: none;
          }
        }
      }
      .th {
        color: $SURF_SECONDARY;
        position: sticky;
        top: 72px;
        z-index: 10;
      }
      .th:nth-child(2) {
        color: $SURF_GRAY;
      }
    }
    .thead {
      display: table-header-group;

      .tr {
        .th {

          button {
            padding: 1rem .5rem;
            outline: none;
            border: none;
            background: none;
            margin-left: -.5rem;
            color: $SURF_SECONDARY;
            width: 100px;
            text-align: left;
            @include transition(0.15s);
            white-space: nowrap;

            &.active {
              font-weight: 600;
              color: $SURF_GRAY;
              @include transition(0.3s);
            }

            &:hover {
              color: $SURF_GRAY;
            }
          }

          &:last-child {
            text-align: right;
            
            button {
              text-align: right;
              margin-right: -.5rem;
            }
          }

          &:nth-last-child(2) {
            text-align: right;

            button {
              text-align: right;
              margin-right: -.5rem;
            }
          }
        }
      }
    }
    .tbody {
      display: table-row-group;
      opacity: 1;
      @include transition(0.5s);

      &.sorting {
        opacity: 0;
        @include transition(0.1s);
      }

      .tr {
        position: relative;
        height: 56px;
        cursor: pointer;
        box-shadow: inset 0 1px 0 0 #EBECEC;

        .td {
          height: 56px;
          padding-left: 0;
          padding: 1rem 0;
          position: relative;
          font-variant-numeric: tabular-nums;
          letter-spacing: -0.03rem;
          white-space: nowrap;

          &:nth-child(2) {
            &:before {
              position: absolute;
              left: -4rem;
              top: 1.1rem;
              height: 56px;
              color: $SURF_TERTIARY;
              @include transition(0.1s);
              z-index: 1;
              display: block;
              font-style: normal;
              font-weight: normal;
              font-size: 13px;
              line-height: 20px;
              letter-spacing: -0.04px;

              @media (max-width: 1199px) {
                display: none;
                content: none !important;
              }
            }
          }

          &:last-child {
            text-align: right;
          }

          &:nth-last-child(2) {
            text-align: right;
            width: 1%;
          }
        }

        &:after {
          content: "";
          position: absolute;
          left: -1.25rem;
          right: -1.25rem;
          top: 0;
          height: 56px;
          border-radius: 12px;
          background: transparent;
          @include transition(0.1s);
          z-index: 1;
          display: block;
        }

        &:hover {
          box-shadow: none;
          background: transparent;

          &:after {
            background: #ffffff;
            @include transition(0.25s);
          }
          & + .tr {
            box-shadow: none;
          }
        }
        * {
          position: relative;
          z-index: 2;
        }

        @for $i from 1 through 100 {
          &:nth-child(#{$i}) {
            .td:nth-child(2):before {
              content: "#{$i}";
            }
          }
        }
        &.full-stake {
          .td {
            &:last-child {
              color: $SURF_TERTIARY;
            }

            &:nth-last-child(2) {
              color: $SURF_TERTIARY;
            }
          }
        }
      }
    }
  }
  .table-container {
    min-height: 200px;
    margin-bottom: 80px;

    .spinner {
      margin: 60px auto;
    }

    &:before {
      content: "";
      height: 52px;
      top: 72px;
      left: 0;
      position: fixed;
      opacity: 0;
      pointer-events: none;
      width: 100%;
      z-index: 10;
      @include transition(0.15s);
    }
    &.sticky {
      &:before {
        @include transition(0.25s);
        opacity: 1;
        // background: #FFFFFFd0;
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);

        background: rgba($SURF_GRAY_BACKGROUND_2, 0.6);
        box-shadow1: 0px -1px 1px rgba(32, 38, 42, 0.01), 0px 2px 2px rgba(134, 135, 160, 0.03), 0px 4px 4px rgba(134, 135, 160, 0.03), 0px 8px 8px rgba(134, 135, 160, 0.03);
      }
      .th {
        box-shadow: 0px 1px 0px rgba(32, 38, 42, 0.04) !important;
      }
    }

  }
  .scroll-trigger {
    width: 1px;
    height: 1px;
    transform: translateY(-72px);
    &.scroll-trigger-2 {
      transform: translateY(-122px);
    }
  }
  .links-container {
    [class^="col"] {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      a {
        display: flex;
        line-height: 2rem;
        margin-bottom: 1rem;
      }
    }
  }
  .table-container {
    .flex {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
  .index {
    color: $SURF_TERTIARY;
    @include transition(0.1s);
    display: block;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: -0.04px;
    min-width: 1.5rem;
    white-space: nowrap;
  }
  .score {
    flex-grow: 0;

    &:after {
      content: "";
      background-image: url("data:image/svg+xml,%3Csvg width='12' height='11' viewBox='0 0 12 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6.00001 0.5C6.19032 0.5 6.36413 0.602794 6.44837 0.765165L7.87715 3.51925L11.0723 3.96361C11.2606 3.9898 11.4169 4.11539 11.4756 4.28761C11.5343 4.45984 11.4852 4.64882 11.3489 4.77515L9.03728 6.91739L9.58282 9.94384C9.61499 10.1223 9.53787 10.3027 9.38388 10.4092C9.22989 10.5156 9.02575 10.5296 8.85729 10.4453L6.00001 9.01563L3.14274 10.4453C2.97428 10.5296 2.77014 10.5156 2.61615 10.4092C2.46216 10.3027 2.38504 10.1223 2.41721 9.94384L2.96275 6.91739L0.651148 4.77515C0.514839 4.64882 0.465703 4.45984 0.524392 4.28761C0.58308 4.11539 0.73942 3.9898 0.9277 3.96361L4.12288 3.51925L5.55166 0.765165C5.6359 0.602794 5.80971 0.5 6.00001 0.5Z' fill='%230083E0'/%3E%3C/svg%3E%0A");
      vertical-align: middle;
      background-repeat: no-repeat;
      background-position: top 1px right 0;
      width: 1rem;
      height: 1rem;
      display: inline-block;
      margin-left: .25rem
    }
  }
  .tooltip-header {
    z-index: 15;
  }
  .addr {
    flex-grow: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: -0.04px;
    color: $SURF_TERTIARY;
    padding-left: 1rem;
  }

  .stats-container {
    margin-bottom: 60px;
  }
  
  .everscale-depools-container {
    margin-bottom: 3rem;

    h3 {
      margin-bottom: 3rem;
    }

    [class^="col"] {
      display: flex;
      flex-direction: row;
      justify-content: stretch;
      align-items: stretch;

      .depooler-stats-cards {
        flex-grow: 1;

        @media (max-width: 767px) {
          .stat-card:nth-child(2) {
            width: calc(50% - 1rem);
          }
        }
      }
      .depooler-stats-cards-logo {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding-left: 0;
        padding-right: 0;
        min-height: 100px;

        @media (max-width: 767px) {
          margin-top: 1.75rem;
        }

        svg {
          color: rgba($dark-alt, 1);
          @include transition(0.1s);
          max-width: 90%;
        }
    
        &:hover {
          svg {
            color: rgba($surf-blue, 1);
            @include transition(0.3s);
          }
        }
      }
    }
    @media (max-width: 450px) {
      .col-6 {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  }

  .depooler-stats-cards {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    z-index: 10;
    padding: 1.5rem 1.5rem;
    box-shadow: 0px 20px 75px -30px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    border-radius: 12px;

    animation: fadeIn 1s cubic-bezier(0.375, 0.885, 0.6, 1);
    -webkit-animation: fadeIn 1s cubic-bezier(0.375, 0.885, 0.6, 1);
    -moz-animation: fadeIn 1s cubic-bezier(0.375, 0.885, 0.6, 1);
    -o-animation: fadeIn 1s cubic-bezier(0.375, 0.885, 0.6, 1);
    -ms-animation: fadeIn 1s cubic-bezier(0.375, 0.885, 0.6, 1);

    @media (max-width: 600px) {
      display: grid;
      grid-gap: 1rem;
      justify-content: space-around;
      align-items: center;
      grid-template: [row1-start] "cell cell" auto [row1-end]
                     [row2-start] "cell cell" auto [row2-end]
                     / 45% 45%;
      justify-items: start;
    }
    @media (max-width: 450px) {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }

  .stat-card {
    position: relative;
    cursor: default;

    &:last-child {
      padding-right: 1rem;
    }
    &:nth-child(4){
      @media (max-width: 767px) {
        display: none;
      }
      @media (max-width: 450px) {
        display: block;
      }
    }

    &:before {
      position: absolute;
      content: '';
      z-index: -1;
      opacity: 0.09;
      background-size: cover;
      @include transition(0.15s);
    }

    &:hover:before {
      opacity: 0.2;
      @include transition(0.25s);
    }
    & > .card-title {
      margin-bottom: 3px;
      white-space: nowrap;
    }
    & > .value-wrapper {
      display: flex;
      justify-content: flex-start;
    }
    & .value {

    }
    &.staking-card-ton {
      & .value {
        padding-right: 1.5rem;
  
        &:after {
          content: "Ē";
          margin-left: 5px;
        }
      }
    }
  }

  .depooler-form {
    & > * {
      margin-bottom: 1rem;
    }
  }

  .swiper-pagination-bullet {
    background: none;
    opacity: 1;
    width: 10px;
    height: 10px;
    margin: 0 0 1px !important;
    @include transition(0.15s);
    display: flex;
    justify-content: center;
    align-items: center;
    

    &:before {
      content: '';
      display: block;
      background-color: #E2E3E4;
      opacity: 1;
      width: 6px;
      height: 6px;
      border-radius: 3px;
      @include transition(.15s);
    }

    &.swiper-pagination-bullet-active {
      background: none;
      height: 14px;

      &:before {
        background-color: $SURF_GRAY;
        height: 10px;
      }
    }
  }

  .spinner {
    display: block;
    opacity: 1;
    mix-blend-mode: multiply;
    width: $spinnerSize + px;
    height: $spinnerSize + px;
    x: 0px;
    y: 0px;
    viewBox: 0 0 $spinnerSize $spinnerSize;
    
    circle {
      fill: transparent;
      stroke: $surf-blue;
      stroke-width: 3;
      stroke-linecap: round;
      stroke-dasharray: (3.14 * $spinnerSize);
      -webkit-transform-origin: (0.5px * $spinnerSize) (0.5px * $spinnerSize) 0;
      -moz-transform-origin: (0.5px * $spinnerSize) (0.5px * $spinnerSize) 0;
      -ms-transform-origin: (0.5px * $spinnerSize) (0.5px * $spinnerSize) 0;
      -o-transform-origin: (0.5px * $spinnerSize) (0.5px * $spinnerSize) 0;
      transform-origin: (0.5px * $spinnerSize) (0.5px * $spinnerSize) 0;
      -webkit-animation: spinner 3s linear infinite;
      -moz-animation: spinner 3s linear infinite;
      -ms-animation: spinner 3s linear infinite;
      -o-animation: spinner 3s linear infinite;
      animation: spinner 3s linear infinite;
    }
  }
}

.round-bar {
  display: block;
  opacity: 1;
  mix-blend-mode: multiply;
  width: $spinnerSize + px;
  height: $spinnerSize + px;
  x: 0px;
  y: 0px;
  viewBox: 0 0 $spinnerSize $spinnerSize;
  
  circle.circle-back {
    fill: transparent;
    stroke: #E2E3E4;
    stroke-width: 3;
    stroke-linecap: round;
  }

  circle:not(.circle-back) {
    fill: transparent;
    stroke: $surf-blue;
    stroke-width: 3;
    stroke-linecap: butt;
    stroke-dashoffset: 0;
    stroke-dashoffset: (1.4 * $spinnerSize);
  }
}

.isSafari {
  &.depooler {

    .table {
      .tbody {

        .tr {
          box-shadow: none;

          .td {
            box-shadow: inset 0 1px 0 0 #EBECEC;
          }

          &:after {
            content: none;
            display: none;
          }

          &:hover {
            box-shadow: none;
            background: transparent;

            .td {
              background: #ffffff;
              @include transition(0s);
              box-shadow: none;

              &:first-child, &:nth-child(2) {
                &:after {
                  position: absolute;
                  content: '';
                  border-radius: 12px 0 0 12px;
                  left: -1rem;
                  width: 100%;
                  top: 0;
                  height: 56px;
                  background: #ffffff;
                  @include transition(0s);
                  z-index: 1;
                  display: block;
                }
              }
              &:last-child {
                &:after {
                  position: absolute;
                  content: '';
                  border-radius: 0 12px 12px 0;
                  right: -1rem;
                  width: 100%;
                  top: 0;
                  height: 56px;
                  background: #ffffff;
                  @include transition(0s);
                  z-index: -1;
                  display: block;
                }
              }
            }

            &:after {
              background: #ffffff;
              @include transition(0.25s);
            }
            & + .tr {
              box-shadow: none;

              .td {
                box-shadow: none;
              }
            }
          }
        }
      }
    }
  }
}

.modal-depooler {
  &.modal {
    top: 80px;
    display: flex !important;
    justify-content: center;
    align-items: center;

    &.qr-open {
      form {
        *:not(.modal-buttons) {
          opacity: 0;
          pointer-events: none;
          visibility: hidden;
          cursor1: default;
        }
        .button-qr {
          &, * {
            opacity: 1;
            pointer-events: all;
            visibility: visible;
          }
        }
      }
    }
  
    .modal-dialog {
      width: 440px;

      .modal-content{
        .modal-header {
          padding: 2rem;
          padding-bottom: 0rem;
          border-radius: 1.25rem 1.25rem 0 0;
          line-height: 20px;
          overflow: visible;
          justify-content: flex-start;
          
          button {
            &.btn-close {
              background-size: 1.125rem;
              margin: 0;
            }
          }
        }
      }
    }
    .modal-buttons {
      display: flex;
      flex-direction: row;
      
      button:first-child {
        flex-grow: 1;
        margin-right: 1rem;
      }
      .button-qr {
        width: 56px;
        min-width: 56px;

        svg {
          transform: translateY(-1px);
        }
      }
    }
  }
  &.isMobile {
    &.modal {
      .modal-dialog {
        .modal-content{
          .modal-header {
            padding: 1.5rem 1.5rem 0 1.5rem;
          }
          .modal-body {
            padding: 1.5rem;
          }
        }
      }
    }
    .modal-buttons {
      button:first-child {
        flex-grow: 1;
        margin-right: 0;
      }
      .button-qr {
        display: none;
      }
    }
  }
}


.isMobile {
  &.depooler {

    .table {
      margin-left: 0;
      margin-right: 0;

      .tbody {

        .tr {
          .td {
            .flex {
              .addr {
                display: none;
              }
            }
          }

          &:after {
            content: none;
            display: none;
          }

          &:hover {
            box-shadow: none;
            background: transparent;

            .td {
              background: transparent;
              box-shadow: none;

              &:first-child, &:nth-child(2) {
                &:after {
                  content: none;
                  display: none;
                }
              }
              &:last-child {
                &:after {
                  content: none;
                  display: none;
                }
              }
            }

            &:after {
              background: transparent;
            }
            & + .tr {
              box-shadow: none;

              .td {
                box-shadow: none;
              }
            }
          }
        }
      }
    }
  }
}


@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    stroke-dashoffset: (1.4 * $spinnerSize);
  } 50% {
    -webkit-transform: rotate(720deg);
    stroke-dashoffset: (3.14 * $spinnerSize);
  } 100% {
    -webkit-transform: rotate(1080deg);
    stroke-dashoffset: (1.4 * $spinnerSize);
  }
}

@-moz-keyframes spinner {
  0% {
    -moz-transform: rotate(0deg);
    stroke-dashoffset: (1.4 * $spinnerSize);
  } 50% {
    -moz-transform: rotate(720deg);
    stroke-dashoffset: (3.14 * $spinnerSize);
  } 100% {
    -moz-transform: rotate(1080deg);
    stroke-dashoffset: (1.4 * $spinnerSize);
  }
}

@-ms-keyframes spinner {
  0% {
    -ms-transform: rotate(0deg);
    stroke-dashoffset: (1.4 * $spinnerSize);
  } 50% {
    -ms-transform: rotate(720deg);
    stroke-dashoffset: (3.14 * $spinnerSize);
  } 100% {
    -ms-transform: rotate(1080deg);
    stroke-dashoffset: (1.4 * $spinnerSize);
  }
}

@-o-keyframes spinner {
  0% {
    -o-transform: rotate(0deg);
    stroke-dashoffset: (1.4 * $spinnerSize);
  } 50% {
    -o-transform: rotate(720deg);
    stroke-dashoffset: (3.14 * $spinnerSize);
  } 100% {
    -o-transform: rotate(1080deg);
    stroke-dashoffset: (1.4 * $spinnerSize);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
    stroke-dashoffset: (1.4 * $spinnerSize);
  } 50% {
    transform: rotate(720deg);
    stroke-dashoffset: (3.14 * $spinnerSize);
  } 100% {
    transform: rotate(1080deg);
    stroke-dashoffset: (1.4 * $spinnerSize);
  }
}


@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-moz-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-webkit-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-o-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-ms-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}
