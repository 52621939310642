@import '../../../assets/styles/mixins.scss';


.dropdown {
  position: relative; 

  &.nav-dropdown {
    & > a {
      padding: 0.5rem;
      color: white;
      @include transition(0.15s);

      &:after {
        content: none;
      }

      svg {
        margin-top: -2px;
        margin-left: .5rem;
      }

      &:hover {
        &, &:focus, &:active, &:hover {
          color: rgba(white, .7);
        }
      }

      &, &:focus, &:active, &:hover {
        outline: none;
      }
      &[aria-expanded="true"] {
        color: white;
        @include transition(0.05s);
      }
    }
    [aria-disabled="true"], .disabled {
      &, &:focus, &:active, &:hover {
        color: rgba(white, .3);
        cursor: default;
      }
    }
    .disabled {
      color: rgba(white, .3);
    }
    & > div {
      z-index: 2000;
      background-color: transparent;
      box-shadow: none;
      border: none;
      margin-top: 0;
      margin: 0;
      margin-top: -3rem;
      margin-left: -.75rem;
      box-shadow: 0px 32px 48px rgba(22, 26, 29, 0.5);
      padding: .5rem 1rem 0;
      background: lighten(black, 0%);
      border-radius: 1rem;
      @include transition(0.0s);

      &, * {
        color: white;
      }

      & > * {
        margin-bottom: 1rem;
        padding: 0 .25rem;
        border-radius: .125rem;

        &:focus, &:focus-visible {
          &, &:focus, &:active, &:hover {
            background-color: transparent;
            border-width: 0;
            outline: 2px solid rgba(blue, 0.5);
          }
        }
        &:active {
          &, &:focus, &:active, &:hover {
            background-color: transparent;
            color: rgba(white, .7);
            outline: none;
          }
        }
        &:hover {
          &, &:focus, &:active, &:hover {
            background-color: transparent;
            color: rgba(white, .7);
          }
        }
        &:last-child {
          margin-bottom: .5rem;
        }
      }
    }
  }
}