@import '../../../assets/styles/colors.scss';
@import '../../../assets/styles/mixins.scss';


.switch {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;

  label {
    cursor: pointer;
  }
  input {
    position: relative;
    appearance: none;
    outline: none;
    width: 2rem;
    height: 1rem;
    background-color: $SURF_GRAY;
    border: none;
    border-radius: 1rem;
    transition-duration: .2s;
    margin-left: .5rem;
    cursor: pointer;

    &:after {
      content: "";
      position: absolute;
      top: 2px;
      left: 2px;
      width: .75rem;
      height: .75rem;
      background-color: $white;
      border-radius: 50%;
      @include transition(0.15s);
    }
    &:checked {
      box-shadow: inset 40px 0 0 0 $blue;

      &:after {
        transform: translateX(1rem);
      
        @include transition(0.3s);
      }
    } 
  }
}