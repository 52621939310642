@import '../colors.scss';
@import '../mixins.scss';

.content-wrapper {
  margin-top: 70px;
  margin-bottom: 100px;

  h1 {
    
  }
  a {
    scroll-margin: 20px;
    font-weight: 400;
  }
  p, li {
    font-weight: 400;
  }
  p {
    margin-bottom: 1.5rem;
    
    b, strong {
      font-weight: 600;
    }
  }
  h4 {
    margin-top: 50px;
    margin-bottom: 20px;
  }
  ul, ol {
    margin: 0;
    padding: 0;
    margin-top: 20px;
    margin-bottom: 20px;

    li {
      position: relative;
      padding-left: 2rem;
      list-style-type: none;
      margin-bottom: 15px;

      &:before {
        content: '';
        position: absolute;
        height: 1px;
        width: 10px;
        background-color: #bec4c8;
        color: #bec4c8;
        left: 0;
        top: 13px;
      }
    }
  }
  ul ol {
    li:nth-child(1):before {content: 'a)';}
    li:nth-child(2):before {content: 'b)';}
    li:nth-child(3):before {content: 'c)';}
    li:nth-child(4):before {content: 'd)';}
    li:nth-child(5):before {content: 'e)';}
    li:nth-child(6):before {content: 'f)';}
    li:nth-child(7):before {content: 'g)';}
    li:nth-child(8):before {content: 'h)';}
    li:nth-child(9):before {content: 'i)';}
    li:nth-child(10):before {content: 'j)';}
    li:before {
      position: absolute;
      background-color: transparent;
      color: #bec4c8;
      left: 0;
      top: auto;
    }
  }
  ol {
    li:nth-child(1):before {content: '1.';}
    li:nth-child(2):before {content: '2.';}
    li:nth-child(3):before {content: '3.';}
    li:nth-child(4):before {content: '4.';}
    li:nth-child(5):before {content: '5.';}
    li:nth-child(6):before {content: '6.';}
    li:nth-child(7):before {content: '7.';}
    li:nth-child(8):before {content: '8.';}
    li:nth-child(9):before {content: '9.';}
    li:nth-child(10):before {content: '10.';}
    li:before {
      position: absolute;
      background-color: transparent;
      color: #bec4c8;
      left: 0;
      top: auto;
    }
  }

  .toc {
    a.collapse-control {
      margin-bottom: 20px;
      display: inline-block;

      &:after {
        content: '';
        width: 16px;
        height: 12px;
        display: inline-block;
        background-image: url("data:image/svg+xml,%3Csvg width='16' height='10' viewBox='0 0 16 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.828369 1.48535L7.89944 8.55642L14.9705 1.48535' stroke='%233888FF' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
        background-position: center 1px;
        background-repeat: no-repeat;
        margin-left: 0.5rem;
        transform: rotate(180deg);
        @include transition(0.15s);
      }
      &.collapsed:after {
        transform: rotate(0deg);
        @include transition(0.35s);
      }
    }
    .card-toc {
      border-radius: 20px;
      border: none;
      background-color: $SURF_GRAY_BACKGROUND;
      
      a {
        display: block;
        margin-bottom: 0.5rem;
      }
      
      p {
        margin: 0;
      }
    }
  }
}