.staking {
  background-color: #FAFAFA;

  main {
    padding-top: 65px;
    padding-bottom: 60px;

    @media (max-width: 575px) {
      padding-top: 10px;
    }
  }

  .hero {
    display: grid;
    grid-template-columns: [first] 350px [line2] auto [last];
    grid-template-rows: [start] 130px [row-1] 95px [row-2] 70px [row-3] 115px [row-4] 80px [end];
    grid-template-areas: 
    "header void"
    "description void"
    "cta void"
    "what-is-staking void"
    "qr-code void";
    justify-items: start;
    align-items: start;
    justify-content: start;
    background-image: url('../../images/illustrations/staking-hero.png');
    background-position: top right;
    background-size: 620px auto;
    background-repeat: no-repeat;
    margin-top: 0;
    margin-bottom: 100px;

    padding-top: 20px;

    @media (max-width: 991px) {
      background-size: 500px auto;
      grid-template-rows: [start] 130px [row-1] 95px [row-2] 70px [row-3] 70px [row-4] 80px [end];
    }
    @media (max-width: 767px) {
      background-size: 80vw auto;
      background-position: top center;
      padding-top: calc(-200px + 100vw);
    }
    @media (max-width: 575px) {
      background-image: none;
      padding-top: 0;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      margin-bottom: 60px;
    }

    h1 {
      grid-area: header;
      font-size: 2.25rem;
      line-height: 3rem;
      padding-top: 20px;
      white-space: pre-line;

      @media (max-width: 575px) {
        margin-bottom: 20px;
        font-size: 2rem;
        line-height: 2.5rem;
        white-space: normal;
      }
    }
    .page-description {
      grid-area: description;
      font-size: 1.0625rem;

      &:after {
        @media (max-width: 575px) {
          display: block;
          content: '';
          height: calc(3 * 80vw / 4);
          margin-top: 20px;
          margin-bottom: 20px;
          padding-bottom: calc(-150px + 100vw);
          background-image: url('../../images/illustrations/staking-hero.png');
          background-repeat: no-repeat;
          background-position: 0px center;
          background-size: contain;
        }
      }
    }
    & > button {
      grid-area: what-is-staking;
      width: 170px;
      min-width: 170px;
      font-size: 15px;
      font-weight: 500;
      color: $surf-blue;
      outline: none !important;
      border: none !important;
      box-shadow: none !important;

      @media (max-width: 575px) {
        margin: 0px auto 30px;
      }
    }
    .buttons-container {
      grid-area: cta;
      display: flex;
      flex-direction: row;
      justify-content: stretch;

      a {
        flex-grow: 1;

        @media (max-width: 767px) {
          width: 100%;
        }
        @media (max-width: 575px) {
          margin-bottom: 20px;
        }
        button {
          &.btn-xl {
            width: 170px;
            min-width: 170px;
            position: relative;
            @include transition(0.15s);

            &:after {
              content: '';
              position: absolute;
              top: 24px;
              opacity: 0;
              right: 36px;
              background-repeat: no-repeat;
              background-position: center -2px;
              width: 16px;
              display: block;
              height: 16px;
              margin-left: 8px;

              @include transition(0.15s);
              background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.75 8H13.25' stroke='%23FDFDFD' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M8 2.75L13.25 8L8 13.25' stroke='%23FDFDFD' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
              background-size: 18px 18px;
            }
            &:hover {
              padding-right: 2rem;
              @include transition(0.35s);
            }
            &:hover:after {
              @include transition(0.35s);
              transform: translateX(10px);
              opacity: 1;
            }
            @media (max-width: 575px) {
              width: 100%;
            }
          }
        }
      }
      .qr-code {
        grid-area: qr-code;
        flex-grow: 0;
        width: 62px;
        height: 62px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: .625rem;
        @include transition(0.1s);
        max-height: 130px;
        max-width: 110px;
        box-shadow: 0 0 0 1px $surf-blue;
        border-radius: 12px;
        margin-left: 1rem;
        position: relative;
        z-index: 1000;
        background-color: #ffffff;

        svg {
          width: 64px;
          height: 64px;
        }
        &:hover {
          transform: scale(2);
          @include transition(0.25s);
          box-shadow: 0 0.03rem 0 0.05rem rgba($surf-blue, 0.075), 0 30px 30px 0 rgba(#050520, 0.15);
        }
        &:first-of-type {
          @media (max-width: 419px) {
            grid-area: card-one;
          }
        }
        &:last-of-type {
          @media (max-width: 419px) {
            grid-area: card-two;
          }
        }
      }

    }
  }
  .staking-stats-cards {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 110px;
    position: relative;
    z-index: 10;
    padding-left: 1rem;
    padding-right: 1rem;

    @media (max-width: 991px) {
      display: grid;
      margin-bottom: 30px;
      
      justify-content: space-around;
      align-items: center;
      grid-template: [row1-start] "cell cell" auto [row1-end]
                     [row2-start] "cell cell" auto [row2-end]
                     / 45% 45%;
      justify-items: start;
    }
    @media (max-width: 767px) {
      padding-left: 1rem;
      padding-right: 1rem;
    }
    @media (max-width: 575px) {
      padding-left: 0rem;
      padding-right: 0rem;
    }
  }
  .staking-promo-cards {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 80px;

    @media (max-width: 991px) {
      display: grid;
      justify-content: space-around;
      justify-items: start;
      align-items: flex-start;
      grid-template: [row1-start] "cell cell cell" auto [row1-end]
                     [row2-start] "cell cell cell" auto [row2-end]
                     / auto auto;
    }
    @media (max-width: 767px) {
      display: grid;
      justify-content: space-around;
      justify-items: start;
      align-items: center;
      grid-template: [row1-start] "cell cell" auto [row1-end]
                     [row2-start] "cell cell" auto [row2-end]
                     [row3-start] "cell cell" auto [row3-end]
                     / auto auto;
    }
    @media (max-width: 575px) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-bottom: 3rem;
    }

    .promo-card {
      width: 16%;
      margin-bottom: 40px;
      
      @media (max-width: 991px) {
        width: 70%;
      }
      @media (max-width: 767px) {
        margin-bottom: 50px;
        
        &.pulse-card__balance {
          margin-bottom: 30px;
        }
        width: 70%;
      }
      @media (max-width: 575px) {
        width: 100%;
        display: grid;
        grid-template-columns: [first] 100px [col-1] minmax(min-content, 280px) [col-2] auto [last];
        grid-template-rows: [start] minmax(min-content, 1%) [row-1] auto [end];
        grid-gap: 1rem;
        grid-template-areas: 
        "icon title filler"
        "icon description filler";
        margin-bottom: 3rem;
      }
      
      .title {
        margin-bottom: .5rem;
        white-space: nowrap;

        @media (max-width: 575px) {
          grid-area: title;
          margin-bottom: 0;
        }
      }
      .description {
        width: minmax(max-content, 300px);

        @media (max-width: 575px) {
          grid-area: description;
        }
      }
      
      div[role=button] {
        margin: 0 !important;
        margin-bottom: 25px !important;
        cursor: default !important;
        background-repeat: no-repeat;

        @media (max-width: 575px) {
          grid-area: icon;
          flex-shrink: 0;
          flex-grow: 0;
        }
      }
      &.fowls div[role=button] {
        background-image: url('../../images/illustrations/fowls.png');
        background-size: 100px 100px;
        background-position: 0 -1px;
      }
      &.apple div[role=button] {
        background-image: url('../../images/illustrations/apple.png');
        background-size: 100px 100px;
        background-position: 4px 0px;
      }
      &.flower div[role=button] {
        background-image: url('../../images/illustrations/flower.png');
        background-size: 100px 100px;
        background-position: 0 3px;
      }
      &.rooster div[role=button] {
        background-image: url('../../images/illustrations/rooster.png');
        background-size: 100px 100px;
        background-position: 2px -2px;
      }
      &.dudes div[role=button] {
        background-image: url('../../images/illustrations/dudes.png');
        background-size: 100px 100px;
        background-position: -1px 0px;
      }
    }
  }

  .stat-card {
    margin-bottom: 40px;
    position: relative;
    cursor: default;

    @media (max-width: 991px) {
      margin-bottom: 120px;
    }

    &:before {
      position: absolute;
      content: '';
      z-index: -1;
      opacity: 0.09;
      background-size: cover;
      @include transition(0.15s);
    }

    &:nth-child(1){
      min-width: 245px;

      .value-wrapper {
        min-width: 220px;

        @media (max-width: 575px) {
          min-width: 0;
        }
      }
      .value {
        min-width: 207px;

        @media (max-width: 575px) {
          min-width: 0;
        }
      }

      &:before {
        background-image: url('../../images/svg/hills.svg');
        top: -40px;
        left: 18px;
        width: 181px;
        height: 145px;
        
        @media (max-width: 575px) {
          left: -30px;
        }
      }
    }

    &:nth-child(2){
      min-width: 195px;

      .value-wrapper {
        min-width: 180px;

        @media (max-width: 575px) {
          min-width: 0;
        }
      }
      .value {
        min-width: 165px;

        @media (max-width: 575px) {
          min-width: 0;
        }
      }
      
      &:before {
        background-image: url('../../images/svg/upward.svg');
        top: -40px;
        left: -40px;
        width: 133px;
        height: 138px;
        
        @media (max-width: 575px) {
          left: 0px;
        }
      }
    }

    &:nth-child(3){
      min-width: 140px;

      .value-wrapper {
        min-width: 140px;
      }
      
      &:before {
        background-image: url('../../images/svg/brush.svg');
        top: -35px;
        left: -43px;
        width: 140px;
        height: 138px;
        
        @media (max-width: 991px) {
          left: -10px;
        }
        @media (max-width: 575px) {
          left: -10px;
        }
      }
    }
    &:nth-child(4){
      min-width: 120px;
      
      &:before {
        background-image: url('../../images/svg/easter.svg');
        top: -40px;
        left: -70px;
        width: 145px;
        height: 145px;
        
        @media (max-width: 575px) {
          left: -20px;
        }
      }
    }
    &:hover:before {
      opacity: 0.2;
      @include transition(0.25s);
    }
    & > .title {
      letter-spacing: -0.02em;
      font-size: 0.875rem;
      font-weight: 400;
      margin-bottom: 3px;
    }
    & > .value-wrapper {
      display: flex;
      justify-content: flex-start;
    }
    & .value {
      font-size: 2.125rem;
      font-weight: 600;
      letter-spacing: -0.02em;
      @include transition(25s);

      @media (max-width: 575px) {
        letter-spacing: -0.04em;
        font-size: 1.75rem;
      }
    }
    &.staking-card-ton {
      & .value {
        padding-right: 2rem;
  
        &:after {
          content: "Ē";
          margin-left: 5px;
        }
      }
    }
  }

  .calculator {
    background-color: #FFFFFF;
    border-radius: 20px;
    padding: 2rem;
    box-shadow: 0px 1px 125px -30px rgba(0, 0, 0, 0.1);
    
    position: relative;
    z-index: 2;
    margin: 0 auto;
    max-width: 530px;

    @media (max-width: 991px) {
    }
    @media (max-width: 767px) {
      padding: 2rem;
      margin: 1rem auto;
    }
    @media (max-width: 610px) {
      padding: 2rem;
      margin: 1rem;
    }
    @media (max-width: 575px) {
      padding: 1.5rem;
      margin: 0rem;
    }

    .data {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 2rem;

      & > * {
        flex-basis: 45%;
      }

      .interest {
        text-align: left;
        color: $SURF_GRAY_2;

        @media (max-width: 575px) {
          text-align: right;
        }

        .value {
          color: $SURF_TERTIARY;
          white-space: nowrap;
        }
      }

      .amount {
        color: $SURF_GRAY_2;

        .value {
          font-weight: 600;
          color: #212529;
          white-space: nowrap;

          &:after {
            content: "Ē";
            display: inline-block;
            margin-left: .25rem;
          }
        }
      }

      .value {
        margin-top: 1rem;
        letter-spacing: -0.02em;
      }
    }
    
    .rc-slider {
      height: 34px;
      margin-bottom: 30px;

      .rc-slider-rail {
        background-image: url("data:image/svg+xml,%3Csvg width='20' height='12' viewBox='0 0 20 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.399902 0V12' stroke='black' stroke-width='0.75' stroke-miterlimit='10'/%3E%3Cpath d='M10.3999 0V12' stroke='%23F0F0F0' stroke-miterlimit='10'/%3E%3C/svg%3E%0A");
        background-image: url("data:image/svg+xml,%3Csvg width='10' height='12' viewBox='0 0 10 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='1' height='12' fill='%2320262A'/%3E%3Crect x='5' width='1' height='12' fill='%23E2E3E4'/%3E%3C/svg%3E%0A");
        background-repeat: repeat-x;
        background-position-x: 3px;
        background-color: transparent;
        border-radius: 0;
        height: 12px;
      }

      .rc-slider-track {
        background: none;
      }

      .rc-slider-handle {
        position: absolute;
        width: 30px;
        height: 30px;
        cursor: pointer;
        cursor: -webkit-grab;
        margin-top: -10px;
        cursor: grab;
        border-radius: 50%;
        border: solid 2px $SURF_GRAY;
        background-color: #fff;
        touch-action: pan-x;
        @include transition-property(0.15s, box-shadow, 0);
      }

      .rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
        border: solid 2px $SURF_GRAY;
        box-shadow: 0 0 0 0.2rem rgba(86, 154, 255, .5);
        @include transition-property(0.35s, box-shadow, 0);
      }
      .rc-slider-handle:focus {
        outline: none;
      }
      .rc-slider-handle-click-focused:focus {
        border-color: $SURF_GRAY;
        box-shadow: unset;
      }
      .rc-slider-handle:hover {
        border-color: $SURF_GRAY;
      }
      .rc-slider-handle:active {
        border-color: $SURF_GRAY;
        box-shadow: 0 0 0 0.2rem rgba(86, 154, 255, .5);
        @include transition-property(0.35s, box-shadow, 0);
        cursor: -webkit-grabbing;
        cursor: grabbing;
      }
    }
    .earnings {
        display: grid;
        justify-content: space-between;
        justify-items: left;
        align-items: center;
        margin-bottom: 3rem;
        row-gap: 2rem;
        grid-template: [row1-start] "cell cell" auto [row1-end]
                      [row2-start] "cell cell" auto [row2-end] / 50% 50%;

        @media (max-width: 500px) {
          grid-template: [row1-start] "cell cell" auto [row1-end]
                        [row2-start] "cell cell" auto [row2-end];
        }

      & > * {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        flex-basis: 45%;
        width: 100%;
        color: #707376;
        white-space: nowrap;

        @media (max-width: 575px) {
          width: 100%;
        }

        @media (max-width: 500px) {

          &:nth-child(even) {
            align-items: flex-end;
          }
        }

        &:first-child {
          .value {
            justify-content: flex-start;
          }
        }
        .value {
          letter-spacing: -0.02em;
          padding-top: 7px;
          display: flex;
          flex-direction: row;
          color: #20262A;

          &:after {
            content: "Ē";
            display: inline-block;
            margin-left: .25rem;
          }
        }
      }
    }
    button {
      width: 100%;
    }
  }
  .calculator-wrapper {
    position: relative;

    @media (max-width: 767px) {
      background-color: #F4F4F5;
    }
    .content-container {
      padding-bottom: 100px;

      @media (max-width: 767px) {
        padding-bottom: 1.25rem;
      }
    }

    &:after {
      width: 100%;
      z-index: 1;
      position: absolute;
      height: 255px;
      bottom: 0;
      left: 0;
      background-color: #F4F4F5;
    }
    h3 {
      font-weight: 600;
      margin: 0 auto;
      margin-top: 50px;
      margin-bottom: .875rem;
      position: relative;
      z-index: 2;
      text-align: center;
      max-width: 400px;

      @media (max-width: 767px) {
        padding-left: 1rem;
        margin-bottom: 3rem;
      }
      @media (max-width: 575px) {
        margin-bottom: 3rem;
        padding-left: 0rem;
        width: auto;
      }
    }
    .description {
      max-width: 400px;
      position: relative;
      z-index: 2;
      text-align: center;
      margin: 0 auto;
      margin-bottom: 3rem;
      max-width: 400px;

      @media (max-width: 767px) {
        display: none;
        padding-left: 1rem;
      }
      @media (max-width: 575px) {
        display: none;
      }
    }
  }
  .faq {
    padding-top: 60px;
    overflow: visible;

    h3 {
      font-size: 2.0625rem;
      line-height: 2.25rem;
      font-weight: 600;
      margin-bottom: 1rem;
      
      @media (max-width: 767px) {
        padding-left: 1rem;
      }
      @media (max-width: 575px) {
        padding-left: 0rem;
      }
    }
    .description {
      margin-bottom: 50px;
      max-width: 700px;
      
      @media (max-width: 767px) {
        padding-left: 1rem;
      }
      @media (max-width: 575px) {
        padding-left: 0rem;
      }
    }
    a {
      &.outer {
        margin-top: 15px;
        font-weight: 500;
        
        &:after {
          background-image: url("data:image/svg+xml,%3Csvg width='11' height='11' viewBox='0 0 11 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 1L1 10' stroke='%230083E0' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M10 10V1H1' stroke='%230083E0' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
          background-repeat: no-repeat;
          background-position: center center;
          background-size: 10px 10px;
          content: '';
          width: 11px;
          height: 11px;
          margin-left: 7px;
          display: inline-block;
          @include transition(0.15s);
        }

        &:hover{
          color: $surf-blue;

          &:after {
            transform: translateX(5px);
            @include transition(0.35s);
          }
        }
      }
    }
    ul {
      margin: 0;
      padding: 0;
      margin-bottom: 2rem;
      margin-top: 1.5rem;

      li {
        list-style-type: none;
        background-image: url("data:image/svg+xml,%3Csvg width='13' height='12' viewBox='0 0 13 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.49079 11.6314C3.00987 8.64815 1.19054 5.1286 0.0989323 1.40793C0.0327745 1.17329 -0.0333832 0.905133 0.0327745 0.670496C0.264327 0.100663 1.19054 0.301781 1.65364 0.737535C2.57985 1.57552 3.10911 2.74871 3.63837 3.85485C4.43227 5.63139 5.22616 7.40793 6.02005 9.15094C5.35847 7.77664 4.92845 6.26826 4.72998 4.75988C4.56458 3.31854 4.66382 1.67608 5.65618 0.603457C6.02005 0.234742 6.48316 -0.0669344 7.01242 0.000104683C7.93863 0.100663 8.33557 1.24033 8.46789 2.17888C8.79868 4.82692 8.40173 7.54201 7.37629 9.95541C7.674 8.2124 8.26942 6.5029 9.12947 4.92748C9.75797 3.78781 10.5519 2.68167 11.6435 1.97776C11.875 1.84368 12.1396 1.7096 12.4043 1.77664C12.9997 1.91072 13.0659 2.78223 12.9335 3.38558C12.2058 6.93865 10.6842 8.94983 8.69944 12.0001' fill='%23DE7F71'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-position: 0 2px;
        text-indent: 25px;
        padding-left: 0;
        list-style-position: inside;
        font-size: 0.875rem;
        margin-bottom: 1rem;
      }
    }
  }
}

.staking.isMobile {
  .hero {
    .qr-code {
      display: none;
    }
  }
}

.debot-staking {
  display: flex;
  flex-direction: row;
  margin-top: 5rem;
  margin-bottom: 5rem;

  @media (max-width: 575px) {
    flex-direction: column;
    align-items: center;
  }

  .card {
    text-align: center;

    svg {
      margin: 0 auto;
    }
    .btn {
      min-width: 200px;
    }
  }

  .card:first-of-type {
    width: 80%;
    box-shadow: 0px 1px 125px -30px rgba(0, 0, 0, 0.1);

    .title {
      margin-bottom: 1rem;
    }

    .btn {
      margin-top: 1.5rem;
    }
  }

  .card:last-of-type {

    .title {
      margin-bottom: 1rem;
      margin-top: 1rem;
    }

    svg:last-of-type {
      width: 96px;
      height: auto;
    }

    a svg:last-of-type {
      width: auto;
      height: auto;
    }
  }
}

.about-staking {
  text-align: center;
  margin-bottom: 8rem;
  padding-left: 2rem;
  padding-right: 2rem;

  .paragraph {
    color: #707376;
    margin-bottom: .5rem;
  }
}