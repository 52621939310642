@import '../colors.scss';
@import '../mixins.scss';

.modal {
  top: 80px;

  .modal-dialog {
    .modal-content{
      background-color: $surf-gray-background-alt;
      border-radius: 1.25rem;
      box-shadow: 0px -1px 2px rgba(12, 13, 14, 0.015), 0px 2px 2px rgba(9, 9, 10, 0.02), 0px 4px 4px rgba(12, 12, 14, 0.02), 0px 8px 8px rgba(7, 8, 8, 0.02), 0px 16px 16px rgba(11, 11, 12, 0.07), 0px 48px 48px rgba(22, 22, 26, 0.1);

      .modal-header {
        padding: 0.875rem 1rem;
        border-radius: 1.25rem 1.25rem 0 0;
        overflow: hidden;
        background-color: $surf-gray-background-alt;
        border-bottom: 1px solid $SURF_GRAY_BACKGROUND_2;

        .modal-title {
          font-size: 1rem;
          color: $surf-blue;
          vertical-align: bottom;
          line-height: 1.625rem;
        }

        .logo {
          margin-right: 7px;
          float: left;
          vertical-align: middle;
          
          svg path {
            fill: $surf-blue;
          }
        }
        button {
          &.btn-close {
            &, &:focus, &:active, &:hover {
              outline: none;
              border: none;
              background-color: transparent;
            }

            background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15 1L1 15' stroke='%230073C4' stroke-width='1.75' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M1 1L15 15' stroke='%230073C4' stroke-width='1.75' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
            background-repeat: no-repeat;
            background-position: center;
            width: 1.625rem;
            height: 1.625rem;
          }
        }
      }
      .modal-body {
        padding: 2rem;
      }
      .modal-footer {}
    }
  }
}