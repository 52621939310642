@import '../colors.scss';
@import '../mixins.scss';

.get {
  header {
    display: none;
  }

  footer {
    display: none;
  }
  
  .loader-container {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 2000;
    display: flex;
    justify-content: center;
    align-items: center;
  
    svg {
      width: 1.75rem;
      color: $surf-blue;
      -webkit-animation: loader 2s infinite linear alternate;
      animation: loader 2s infinite linear alternate;
    }
  }
  
  @-webkit-keyframes loader {
    0% {
      transform: rotateY(0deg) scale(1);
    }
    100% {
      transform: rotateY(360deg) scale(0.8);
    }
  }
  
  @keyframes loader {
    0% {
      transform: rotateY(0deg) scale(1);
    }
    100% {
      transform: rotateY(360deg) scale(0.8);
    }
  }
}