$SURF_BETA_BLUE: #343A88;

.beta {
  background-color: $SURF_BETA_BLUE;
  min-width: 320px;

  div[class^="container"], div[class*=" container-"] {
    overflow: visible;
  }

  header {
    background-color: #FFFFFF;
  }

  footer {
    background-color: $SURF_BETA_BLUE;
    border-top: transparent;

    .copyright, .copyright span {
      color: lighten($SURF_BETA_BLUE, 25%);
      opacity: 1;
    }
    
    .dod, a {
      color: lighten($SURF_BETA_BLUE, 25%);
      opacity: 1;
  
      &:hover {
        opacity: 1;
        color: #FFFFFF;
      }
    }
    .link {
      &.link-outer {
        background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1H5M5 1V5M5 1L1 5' stroke='%237279c9' stroke-width='1.25' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");

    
        &:hover {
          background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1H5M5 1V5M5 1L1 5' stroke='%23ffffff' stroke-width='1.25' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
        }
      }
    }
  }

  .btn-primary {
    &, &:focus, &:active {
      border-color: lighten($SURF_BETA_BLUE, 5%);
      background-color: lighten($SURF_BETA_BLUE, 5%);
      outline: none;
    }
    &:hover, &:focus, &:active {
      &, &:hover, &:focus, &:active {
        border-color: saturate(darken($SURF_BETA_BLUE, 5%), 5%);
        background-color: saturate(darken($SURF_BETA_BLUE, 5%), 5%);
      }
    }
  }

  main {
    background-color: $SURF_BETA_BLUE;
    padding-top: 65px;
    padding-bottom: 60px;
    position: relative;
    overflow: hidden;

    @media (max-width: 575px) {
      padding-bottom: 0;
    }

    &:before {
      content: '';
      background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 6198.2 288' style='enable-background:new 0 0 6198.2 288;' xml:space='preserve'%3E%3Cpath fill='%23ffffff' d='M0,0L0,0c46.2,0.4,93.3,4.2,139,11.1C207.9,21.5,269.5,46.2,325,89c21.7,16.8,41.8,35.7,59.8,56.5 c35.9,41.7,65.3,92.1,113.8,118.2c44.8,24.1,98.3,23.7,147.9,23.8c45.9,0.1,5551.8,0,5551.8,0V0'/%3E%3C/svg%3E%0A");
      background-position: top left;
      background-repeat: no-repeat;
      background-size: auto 288px;
      position: absolute;
      top: 0;
      right: -92px;
      width: calc(50% + 106px);
      height: 300px;

      @media (max-width: 1199px) {
        right: -20px;
      }

      @media (max-width: 991px) {
        width: 618px;
      }
      @media (max-width: 767px) {
        width: calc(50% + 305px);
        background-size: auto calc(268px);
        background-position: top left;
        left: 50%;
        transform: translateX(-305px);
      }
      @media (max-width: 575px) {
        width: calc(50% + 150px);
        background-size: auto calc(158px);
        background-position: top left;
        left: 50%;
        transform: translateX(-150px);
      }
      @media (max-width: 419px) {
        width: calc(50% + 190px);
        background-size: auto calc(165px);
        background-position: top left;
        left: 50%;
        transform: translateX(-190px);
      }
    }

    @media (max-width: 575px) {
      padding-top: 10px;
    }
  }

  .hero {
    display: grid;
    grid-template-columns: [first] 450px [line2] auto [last];
    grid-template-rows: [start] 80px [row-1] 125px [row-2] 70px [row-3] 60px [row-4] 150px [row-5] auto [end];
    grid-template-areas: 
    "header void"
    "description void"
    "cta void"
    "what-is-staking void"
    "description-2 void"
    "qr-code void";
    justify-items: start;
    align-items: start;
    justify-content: start;
    background-position: top right -50px;
    background-size: 620px auto;
    background-repeat: no-repeat;
    margin-top: 0;
    margin-bottom: 50px;

    padding-top: 20px;

    &:before {
      content: '';
      background-image: url('../../images/illustrations/beta-hero.png');
      background-position: top left 67px;
      background-repeat: no-repeat;
      background-size: auto 634px;
      position: absolute;
      top: -20px;
      right: 0px;
      width: 576px;
      height: 634px;
      z-index: 6;

      @media (max-width: 1199px) {
        top: 20px;
        right: -10px;
        background-size: auto calc(634px * 0.9);
        background-position: top -25px left 25px;
        grid-template-rows: [start] 160px [row-1] 150px [row-2] 70px [row-3] 70px [row-4] 170px [row-5] 80px [end];
      }
      @media (max-width: 991px) {
        top: 47px;
        right: -80px;
        background-position: top -25px left 25px;
        background-size: auto calc(634px * 0.8);
      }
      @media (max-width: 767px) {
        top: -13px;
        right: 0px;
        background-position: top center;
        background-size: auto 576px;
        width: 100%;
      }
      @media (max-width: 575px) {
        top: -13px;
        right: 0px;
        background-position: top center;
        background-size: auto 428px;
        width: 100%;
      }
      @media (max-width: 419px) {
        top: 30px;
        right: 0px;
        background-position: top center;
        background-size: auto 320px;
        width: 100%;
      }
    }

    @media (max-width: 767px) {
      background-size: 80vw auto;
      background-position: top center;
      padding-top: 600px;
    }
    @media (max-width: 575px) {
      background-image: none;
      padding-top: 0;
      display: flex;
      flex-direction: column;
      padding-top: 420px;
    }
    @media (max-width: 419px) {
      background-image: none;
      padding-top: 0;
      display: flex;
      flex-direction: column;
      padding-top: 360px;
    }

    & > div, & > h1, & > a {
      z-index: 10;
      position: relative;
    }

    h1 {
      grid-area: header;
      padding-top: 20px;
      color: #ffffff;

      @media (max-width: 575px) {
        margin-bottom: 20px;
        font-size: 2rem;
        line-height: 2.5rem;
      }
    }
    .page-description {
      grid-area: description-2;
      font-size: 1.0625rem;
      color: #ffffff;
      max-width: 20rem;
      padding-bottom: 2rem;

      h5 {
        font-size: 1.375rem;
        margin-bottom: 1rem;
      }

      @media (max-width: 575px) {
        font-size: 0.875rem;
        padding-bottom: 0;
      }
    }
    .page-description:first-of-type {
      grid-area: description;
      font-size: 1.0625rem;
      color: #ffffff;
      padding-bottom: 2rem;

      @media (max-width: 575px) {
       font-size: 0.875rem;
       padding-bottom: 2rem;
      }
    }
    .beta-hero-animation {
      position: absolute;
      right: -75px;
      top: -25px;
      z-index: 5;

      @media (max-width: 1199px) {
        right: 15px;
        top: -8px;

        & > * {
          height: calc(634px * 0.9) !important;
          width: calc(576px * 0.9) !important;
        }
      }
      @media (max-width: 991px) {
        right: 3px;
        top: 20px;

        & > * {
          height: calc(634px * 0.8) !important;
          width: calc(576px * 0.8) !important;
        }
      }
      @media (max-width: 767px) {
        top: -13px;
        right: 0px;
        width: 100%;

        & > * {
          height: calc(576px) !important;
          width: calc(576px / 1.100694444444444) !important;
        }
      }
      @media (max-width: 575px) {
        top: -13px;
        right: 0px;
        width: 100%;

        & > * {
          height: calc(428px) !important;
          width: calc(428px / 1.100694444444444) !important;
        }
      }
      @media (max-width: 419px) {
        top: 30px;
        right: 0px;
        width: 100%;

        & > * {
          height: calc(320px) !important;
          width: calc(320px / 1.100694444444444) !important;
        }
      }
    }
    & > .buttons {
      grid-area: cta;

      @media (max-width: 767px) {
        width: 100%;
      }
      @media (max-width: 575px) {
        margin-bottom: 40px;
      }
      button {
        &.btn-primary {
          color: $SURF_BETA_BLUE;
          background-color: #ffffff;
          border-color: #ffffff;

          &:after {
            content: '';
            position: absolute;
            top: 24px;
            opacity: 0;
            right: 1.75rem;
            background-repeat: no-repeat;
            background-position: center -2px;
            width: 16px;
            display: block;
            height: 16px;
            margin-left: 8px;

            @include transition(0.15s);
            background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.75 8H13.25' stroke='%23343A88' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M8 2.75L13.25 8L8 13.25' stroke='%23343A88' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
            background-size: 18px 18px;
          }
          &:hover {
            padding-right: 2rem;
            @include transition(0.35s);
          }
          &:hover:after {
            @include transition(0.35s);
            transform: translateX(10px);
            opacity: 1;
          }
        }
        &.btn-transparent {
          color: #ffffff;
          background-color: transparent;
          border-color: transparent;
        }

        &.btn-icon-arrow-up-right {
          &:after {
            transform: translateY(2px);
            background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.5 4.5L4.5 11.5' stroke='%23ffffff' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M11.5 11.5V4.5H4.5' stroke='%23ffffff' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
          }
          &:hover:after, &:hover:before {
            @include transition(0.35s);
            transform: translateX(-3px) translateY(2px);
            background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.5 4.5L4.5 11.5' stroke='%23ffffff' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M11.5 11.5V4.5H4.5' stroke='%23ffffff' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
          }
        }

        &.btn-xl {
          position: relative;
          min-width: 210px;
          @include transition(0.15s);
          
          @media (max-width: 575px) {
            width: 100%;
          }
        }
      }
    }
    .qr-codes-title {
      color: rgba(156, 181, 255, 0.5);
      mix-blend-mode: overlay;
      margin-bottom: 1rem;
    }
    
    .qr-codes {
      margin-bottom: 2rem;
    }

    .qr-codes-dropdowns {
      @media (max-width: 575px) {
        flex-direction: column;
      }

      svg {
        width: 24px;
        height: 24px;
        vertical-align: middle;
        margin-right: .5rem;

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          fill: white;
          opacity: 0.3;
        }

        &.icon-apple {
          margin-top: -.375rem;
        }
      }
      .dropdown {
        margin-right: 1.5rem;

        @media (max-width: 575px) {
          margin-bottom: 1rem;
        }

        @media (max-width: 520px) {
          margin-right: 1rem;
        }

        a {
          padding-left: 0;
        }
      }
    }

    .qr-codes-container {
      grid-area: qr-code;
      display: flex;
      align-items: center;
      padding-bottom: 4rem;

      @media (max-width: 575px) {
        padding-bottom: 2rem;
      }
    }
    .qr-codes {
      @media (max-width: 525px) {
        width: 100%;
        display: grid;
        grid-template-columns: [first] 1fr [col] 1fr [col] 1fr [last];
        grid-template-rows: [start] 1fr [end];
        grid-gap: 1rem;
        grid-template-areas: 
        "qr qr qr";
      }

      @media (max-width: 420px) {
        width: 100%;
        display: grid;
        grid-template-columns: [first] 1fr [col] 1fr [last];
        grid-template-rows: [start] auto [end];
        grid-gap: 1rem;
        grid-template-areas: 
        "title title"
        "card-one card-two";
      }

      .qr-code {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        text-align: center;
        box-shadow: 0 0 0 1px #6a6cd8;
        background-color: $SURF_BETA_BLUE;
        padding: 1.125rem;
        padding-bottom: 0.75rem;
        border-radius: 0.5rem;
        margin-right: 1rem;
        @include transition(0.1s);
        max-height: 130px;
        max-width: 110px;
        white-space: nowrap;
        position: relative;

        @media (max-width: 525px) {
          max-height: inherit;
          max-width: inherit;
          margin-right: 0;
        }

        svg {
          width: 100%;
          height: auto;
          margin-bottom: 10px;
        }

        &:hover {
          transform: scale(1.4);
          @include transition(0.25s);
          z-index: 10;
          box-shadow: 0 0 0 1px rgba(#6a6cd8, 0.2), 0 30px 30px 0 rgba(#000000, 0.25);
        }
        &:first-of-type {
          @media (max-width: 420px) {

          }
        }
        &:last-of-type {
          @media (max-width: 420px) {

          }
        }
      }

      .flex-grow {
        max-width: 200px;
        font-size: 0.875rem;
        line-height: 1rem;
      }

      .description {
        color: #8F96E0;
        font-size: 0.75rem;
        line-height: 1rem;
        max-width: 103px;

        @media (max-width: 419px) {
          grid-area: title;
          max-width: 100%;
          text-align: center;
        }
      }
    }
  }
}

.beta.isMobile {
  .hero {
    .qr-code:hover {
      transform: none;
      box-shadow: 0 0 0 1px #6a6cd8;
    }
  }
}