@import '../colors.scss';
@import '../mixins.scss';

$SURF_COBALT: #3888FF;

.pulse {
  background-color: #FAFAFA;

  .btn-primary {
    &, &:focus, &:active {
      border-color: $surf-blue;
      background-color: $surf-blue;
      outline: none;
    }
    &:hover, &:focus, &:active {
      &, &:hover, &:focus, &:active {
        border-color: desaturate(darken($SURF_COBALT, 12%), 20%);
        background-color: desaturate(darken($SURF_COBALT, 12%), 20%);
      }
    }
  }

  .hero {
    padding: 40px;
    border-radius: 16px;
    margin-top: 80px;
    
    // @media (min-width: 1200px) {
    //   margin-left: -40px;
    //   margin-right: -40px;
    // }
    // @media (max-width: 767px) {
    //   padding: 20px;
    // }
    
    .title {
    }
    .body {
      flex-grow: 1;

      form{
        display: flex;
        flex-direction: row;
        align-items: center;

        @media (max-width: 767px) {
          flex-direction: column;
          align-items: stretch;
        }

        .form-group {
          flex-grow: 1;
          margin-bottom: 0;
          position: relative;

          button.clear {
            position: absolute;
            right: -8px;
            opacity: 0;
            pointer-events: none;
            top: 50%;
            background: none;
            border: none;
            box-shadow: none;
            outline: none;
            height: 2rem;
            width: 2rem;
            margin-top: -1rem;
            background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM15.6187 8.38128C15.9604 8.72299 15.9604 9.27701 15.6187 9.61872L13.2374 12L15.6187 14.3813C15.9604 14.723 15.9604 15.277 15.6187 15.6187C15.277 15.9604 14.723 15.9604 14.3813 15.6187L12 13.2374L9.61872 15.6187C9.27701 15.9604 8.72299 15.9604 8.38128 15.6187C8.03957 15.277 8.03957 14.723 8.38128 14.3813L10.7626 12L8.38128 9.61872C8.03957 9.27701 8.03957 8.72299 8.38128 8.38128C8.72299 8.03957 9.27701 8.03957 9.61872 8.38128L12 10.7626L14.3813 8.38128C14.723 8.03957 15.277 8.03957 15.6187 8.38128Z' fill='%23FDFDFD'/%3E%3C/svg%3E%0A");
            background-repeat: no-repeat;
            background-position: center;
            @include transition(0.15s);

            &.active {
              opacity: 1;
              pointer-events: all;
              @include transition(0.35s);
            }
          }
        }
        .invalid-feedback {
          color: white;
          height: 19px;
          font-size: 0.9325rem;
          margin-top: 0.5rem;
        }
        input {
          height: 42px;
          font-size: 1.0625rem;
          font-weight: 500;
          padding-right: 2rem;
          text-overflow: ellipsis;

          &::placeholder {
            color: #ffffff;
            text-overflow: ellipsis;
            font-weight: 400;
            opacity: 1;
          }
          &::-webkit-input-placeholder {
            color: #ffffff;
            text-overflow: ellipsis;
            font-weight: 400;
            opacity: 1;
          }
          &::-moz-placeholder, &:-moz-placeholder,&:-ms-input-placeholder {
            color: #ffffff;
            text-overflow: ellipsis;
            font-weight: 400;
            opacity: 1;
          }
          &:-ms-input-placeholder {
            color: #ffffff;
            text-overflow: ellipsis;
            font-weight: 400;
            opacity: 1;
          }
        }
        button.btn {
          flex-grow: 0;
          margin-left: 20px;
          height: 44px;
          min-width: 180px;

          @media (max-width: 767px) {
            margin-top: 20px; 
            margin-left: 0px; 
          }
        }
      }
    }
    &.blue {
      z-index: 2;
      position: inherit;
      background-color: $surf-blue;
      color: #ffffff;
      margin-top: 45px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      height: 150px;

      .form-group {
        label {
          font-size: 0.75rem;
          margin-bottom: .0rem;
          font-weight: 300;
        }
        .form-control {
          font-size: 1rem;
      
          &, &:focus, &:active {
            padding-left: 0;
            padding-right: 0;
            border: none;
            background-color: transparent;
            color: inherit;
            border-bottom: 1px solid #ffffff;
            border-radius: 0;
            height: 56px;
          }
      
          &::placeholder {
            color: #80C9FF;
            text-overflow: ellipsis;
            opacity: 1;
          }
          &::-webkit-input-placeholder {
            color: #80C9FF;
            text-overflow: ellipsis;
            opacity: 1;
          }
          &::-moz-placeholder, &:-moz-placeholder,&:-ms-input-placeholder {
            color: #80C9FF;
            text-overflow: ellipsis;
            opacity: 1;
          }
          &:-ms-input-placeholder {
            color: #80C9FF;
            text-overflow: ellipsis;
            opacity: 1;
          }
        }
      }

      @media (max-width: 767px) {
        height: 238px;
        padding: 30px;
      }
      @media (max-width: 420px) {
        padding: 25px;
        height: 215px;
      }

      .btn {
        background-color: rgba(white, 0.9);
        color: $surf-blue;

        &, &:focus, &:active, &:hover {
          &, &:focus, &:active, &:hover {
            border: none;
          }
        }

        &:hover {
          background-color: rgba(white, 1);
        }

        &[disabled] {
          &, &:focus, &:active, &:hover {
            opacity: 1;
            background-color: #52a5fe;
            color: rgba(white, 0.7);
            border-color: rgba(white, 0);
          }
        }

      }
      .form-group {
        color: #ffffff;

        .form-control:-webkit-autofill,
        .form-control:-webkit-autofill:hover, 
        .form-control:-webkit-autofill:focus,
        .form-control:-webkit-autofill:active,
        .form-control:-webkit-autofill,
        .form-control:-webkit-autofill:hover,
        .form-control:-webkit-autofill:focus,
        .form-control:-webkit-autofill,
        .form-control:-webkit-autofill:hover,
        .form-control:-webkit-autofill:focus {
          background-color: transparent !important;
          color: #ffffff !important;
          -webkit-text-fill-color: #ffffff !important;
          -webkit-box-shadow: 0 0 0px 1000px $surf-blue inset;
        }
      }
    }

    &.white {
      position: relative;
      box-shadow: 0px 5px 10px 0 rgba(33,39,56,0.01);
      margin-top: -10px;
      border-radius: 0 0 16px 16px;
      padding: 0;
      padding-bottom: 2rem;
      padding-top: 2rem;

      &:before {
        height: 150px;
        width: calc(100% - 80px);
        position: absolute;
        top: 15px;
        left: 40px;
        z-index: -1;
        border-radius: 20px;
        box-shadow: 30px 0px 30px 0 rgba(33,39,56,0.1), -30px 0px 30px 0 rgba(33,39,56,0.1);
      }
      &:after {
        height: 150px;
        width: calc(100% - 200px);
        position: absolute;
        bottom: 30px;
        left: 100px;
        z-index: -1;
        border-radius: 20px;
        box-shadow: 0px 20px 30px 0 rgba(33,39,56,0.1), 0px 20px 30px 0 rgba(33,39,56,0.1);
      }
      @media (max-width: 767px) {
        padding-left: 0;
        padding-right: 0;

        &:after, &:before {
          content: none;
        }
      }

      .btn {
        color: $surf-blue;
      }
      .form-group {
        color: #ffffff;
      }
    }
  }
}
.pulse main {
  padding-top: 65px;
  padding-bottom: 160px;
  
  @media (max-width: 575px) {
    padding-top: 10px;
  }

  .page-title {
    padding-top: 20px;
    @media (max-width: 575px) {
      margin-bottom: 20px;
      font-size: 2rem;
      line-height: 2.5rem;
    }
  }

  .page-description {
    font-size: 1.0625rem;
    margin-top: 1.125rem;
    margin-bottom: 80px;
    max-width: 400px;
  }
  .pulse-overview {
    padding-top: 0.5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .title-track {
    padding-top: 50px;
    font-size: 1.875rem;
  }
}

.pulse-card {
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: 767px) {
    margin-bottom: 50px;
    
    &.pulse-card__balance {
      margin-bottom: 30px;
    }
  }

  div[role=button] {
    margin: 0 !important;
    margin-bottom: 1.5rem !important;
    cursor: default !important;
    background-repeat: no-repeat;
  }
  & > h5 {
    letter-spacing: -0.02em;
    font-size: 0.85rem;
    color: $SURF_GRAY_2;
    font-weight: 400;
    margin-bottom: 3px;
  }
  & > .value {
    font-size: 1.875rem;
    font-weight: 600;
    letter-spacing: -0.02em;
    white-space: nowrap;
  }
  & > .description {
    line-height: 23px;
    font-size: 0.9325rem;
  }
  &.pulse-card-ton {
    & > .value {
      padding-right: 1.75rem;

      &.active:after {
        content: "Ē";
        display: inline-block;
        margin-left: .25rem;
      }
    }
  }
  .pulse-card-graph {
    margin-top: 10px;

    &.green {
      color: $surf-green;
      svg * {
        stroke: $surf-green;
      }
    }
    &.red {
      color: $surf-pink;
      svg * {
        stroke: $surf-pink;
      }
    }
    .value {
      line-height: 23px;
      font-size: 0.875rem;
    }
    svg {
      transform: translateY(-12px);
    }
  }
}

.pulse-overview-tracked {
  hr {
    background: none;
    border: none;
    border-bottom: 1px solid #F4F4F5;
    margin-bottom: 50px;
  }
  ul {
    margin: 0;
    padding: 0;

    li {
      list-style-type: none;
      background-image: url("data:image/svg+xml,%3Csvg width='17' height='15' viewBox='0 0 17 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.5 15C11.6421 15 15 11.6421 15 7.5C15 3.35786 11.6421 0 7.5 0C3.35786 0 0 3.35786 0 7.5C0 11.6421 3.35786 15 7.5 15Z' fill='%23FF7183'/%3E%3Cpath d='M3 6L7.5 10.5L16.5 1.5' stroke='%231F262A' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M7.5 15C11.6421 15 15 11.6421 15 7.5C15 3.35786 11.6421 0 7.5 0C3.35786 0 0 3.35786 0 7.5C0 11.6421 3.35786 15 7.5 15Z' fill='%23FF7183'/%3E%3Cpath d='M3 6L7.5 10.5L16.5 1.5' stroke='%231F262A' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position: 0 2px;
      text-indent: 25px;
      max-width: 200px;
      padding-left: 0;
      list-style-position: inside;
      color: #707376;
      font-size: 0.875rem;
    }

    &.standalone {
      margin-top: 85px;

      @media (max-width: 767px) {
        margin-top: 0px;
        margin-bottom: 50px;
        display: flex;
        flex-direction: row;

        li {
          width: 50%;
          flex-grow: 1;
          flex-shrink: 1;
          max-width: 100%;
          padding-right: 20%;

          &:last-child {
            transform: translateX(16px);
          }
        }
      }
    }
  }
  .btn.btn-icon-arrow-up-right {
    padding-left: 0;

    &:after {
      transform: translateY(-3px);
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.5 8.5L8.5 15.5' stroke='%230073C4' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M15.5 15.5V8.5H8.5' stroke='%230073C4' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    }
  }
}
