@import './colors.scss';
@import './mixins.scss';

@import './components/buttons.scss';
@import './components/forms.scss';
@import './components/accordion.scss';
@import './components/typography.scss';
@import './components/modal.scss';

body {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 1rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  letter-spacing: -.01em;
}

a {
  @include transition(0.15s);

  &, &:focus, &:active, &:hover {
    text-decoration: none;
  }
  &:focus, &:active, &:hover {
    @include transition(0.35s);
  }
  &.active {
    color: $surf-blue;
  }
  &.outer {
    margin-top: 15px;
    font-weight: 500;
    
    &:after {
      background-image: url("data:image/svg+xml,%3Csvg width='11' height='11' viewBox='0 0 11 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 1L1 10' stroke='%230083E0' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M10 10V1H1' stroke='%230083E0' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 10px 10px;
      content: '';
      width: 11px;
      height: 11px;
      margin-left: 7px;
      display: inline-block;
      @include transition(0.15s);
    }

    &:hover{
      color: $surf-blue;

      &:after {
        transform: translateX(5px);
        @include transition(0.35s);
      }
    }
  }
}

html, body, #root, .ws-app {
  /* IE 10-11 didn't like using min-height */
  min-height: 100%;
}
.ws-app {
  display: flex;
  flex-direction: column;
}

.container, div[class^="container"]:not(.container-fluid), div[class*=" container-"]:not(.container-fluid) {
  max-width: 1020px;
}
.container-fluid {
  padding-left: 16px;
  padding-right: 16px;
}
div[class^="container"], div[class*=" container-"] {

  @media (max-width: 575px) {
    padding-left1: 20px;
    padding-right1: 20px;
  }
}

header {
  flex-shrink: 0;
}
main {
  flex: 1 0 auto;
}
footer {
  flex-shrink: 0;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Inter', sans-serif;
  font-weight: 600;
}

h1 {
  font-size: 2.25rem;
  letter-spacing: -0.03em;

  small {
    font-size: 30%;
  }
}


.flex-grow {
  flex-grow: 1;
  flex-shrink: 1;
}
.flex-shrink {
  flex-grow: 0;
  flex-shrink: 0;
}

.card {
  background-color: #ffffff;
  border-radius: 20px;
  border: none;
  padding: 2rem;
  box-shadow: 0px 1px 125px -30px rgba(0, 0, 0, 0.05);

  &.card-transparent {
    background-color: transparent;
    box-shadow: none;
  }
}

.content-container {
  overflow: hidden;

  @media (max-width: 991px) {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  @media (max-width: 767px) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  @media (max-width: 575px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  @media (max-width: 399px) {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  
  ul, ol {
    margin: 0;
    padding: 0;
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;

    li {
      position: relative;
      padding-left: 2rem;
      list-style-type: none;
      margin-bottom: 15px;

      &:before {
        content: '';
        position: absolute;
        height: 1px;
        width: 10px;
        background-color: #bec4c8;
        color: #bec4c8;
        left: 0;
        top: 13px;
      }
    }
  }
  ul ol {
    li:nth-child(1):before {content: 'a)';}
    li:nth-child(2):before {content: 'b)';}
    li:nth-child(3):before {content: 'c)';}
    li:nth-child(4):before {content: 'd)';}
    li:nth-child(5):before {content: 'e)';}
    li:nth-child(6):before {content: 'f)';}
    li:nth-child(7):before {content: 'g)';}
    li:nth-child(8):before {content: 'h)';}
    li:nth-child(9):before {content: 'i)';}
    li:nth-child(10):before {content: 'j)';}
    li:before {
      position: absolute;
      background-color: transparent;
      color: #bec4c8;
      left: 0;
      top: auto;
    }
  }
  ol {
    li:nth-child(1):before {content: '1.';}
    li:nth-child(2):before {content: '2.';}
    li:nth-child(3):before {content: '3.';}
    li:nth-child(4):before {content: '4.';}
    li:nth-child(5):before {content: '5.';}
    li:nth-child(6):before {content: '6.';}
    li:nth-child(7):before {content: '7.';}
    li:nth-child(8):before {content: '8.';}
    li:nth-child(9):before {content: '9.';}
    li:nth-child(10):before {content: '10.';}
    li:before {
      position: absolute;
      background-color: transparent;
      color: #bec4c8;
      left: 0;
      top: auto;
    }
  }
}

b {
  font-weight: 600;
}

/**
 * ==============================================
 * Dot Flashing
 * ==============================================
 */
 .loader-spinner {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: $SURF_GRAY_2;
  color: $SURF_GRAY_2;
  -webkit-animation: dot-flashing 1s infinite linear alternate;
  animation: dot-flashing 1s infinite linear alternate;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.loader-spinner::before, .loader-spinner::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}

.loader-spinner::before {
  left: 30px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: $SURF_GRAY_2;
  color: $SURF_GRAY_2;
  -webkit-animation: dot-flashing 1s infinite alternate;
  animation: dot-flashing 1s infinite alternate;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.loader-spinner::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: $SURF_GRAY_2;
  color: $SURF_GRAY_2;
  -webkit-animation: dot-flashing 1s infinite alternate;
  animation: dot-flashing 1s infinite alternate;
  -webkit-animation-delay: .5s;
  animation-delay: .5s;
}

@-webkit-keyframes dot-flashing {
  0% {
    background-color: $SURF_GRAY_2;
  }
  50%,
  100% {
    background-color: lighten($SURF_GRAY_2, 40);
  }
}

@keyframes dot-flashing {
  0% {
    background-color: $SURF_GRAY_2;
  }
  50%,
  100% {
    background-color: lighten($SURF_GRAY_2, 40);
  }
}

.pulse-card .loader-spinner {
  margin: 18px 0 17px 0;
}

dl {
  dt {
    margin-bottom: 0.5rem;
  }
  dd {
    margin-bottom: 2rem;
  }
}

.link {
  &.link-outer {
    padding-right: 1rem;
    background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1H5M5 1V5M5 1L1 5' stroke='%232A3133' stroke-width='1.25' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    background-position: top calc(50% - 8px) right 7px;
    background-size: 6px 6px;
    background-repeat: no-repeat;

    &:hover {
      background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1H5M5 1V5M5 1L1 5' stroke='%230083E0' stroke-width='1.25' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    }
  }
}

.popover {
  border: none;
  border-radius: .5rem;
  max-width: 310px;
  background-color: $surf-gray-background-alt;

  .popover-body {
    padding: 0rem .5rem 0rem 0rem;
  }
}

.no-wrap {
  white-space: nowrap;
}
