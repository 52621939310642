.title {
  &.title-small {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;

    letter-spacing: -0.26px;
    font-feature-settings: 'ordn' on, 'case' on, 'cv05' on, 'cv07' on, 'cv08' on;
  }
  &.title-medium {
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 32px;

    letter-spacing: -0.26px;
    font-feature-settings: 'ordn' on, 'case' on, 'cv05' on, 'cv07' on, 'cv08' on;
  }
  &.title-large {
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 40px;

    letter-spacing: -0.26px;
    font-feature-settings: 'ordn' on, 'case' on, 'cv05' on, 'cv07' on, 'cv08' on;
  }
  &.title-huge {
    font-style: normal;
    font-weight: 600;
    font-size: 2.25rem;
    line-height: 3rem;

    letter-spacing: -0.26px;
    font-feature-settings: 'ordn' on, 'case' on, 'cv05' on, 'cv07' on, 'cv08' on;
  }
}
.title-secondary {
  &.title-secondary-small {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;

    letter-spacing: -0.26px;
    font-feature-settings: 'ordn' on, 'case' on, 'cv05' on, 'cv07' on, 'cv08' on;
  }
  &.title-secondary-medium {
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 32px;

    letter-spacing: -0.26px;
    font-feature-settings: 'ordn' on, 'case' on, 'cv05' on, 'cv07' on, 'cv08' on;
  }
  &.title-secondary-large {
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 40px;

    letter-spacing: -0.26px;
    font-feature-settings: 'ordn' on, 'case' on, 'cv05' on, 'cv07' on, 'cv08' on;
  }
  &.title-secondary-huge {
    font-style: normal;
    font-weight: 400;
    font-size: 2.25rem;
    line-height: 3rem;

    letter-spacing: -0.26px;
    font-feature-settings: 'ordn' on, 'case' on, 'cv05' on, 'cv07' on, 'cv08' on;
  }
}
.paragraph {
  &.paragraph-text {
    font-style: normal;
    font-weight: normal;
    font-size: 1.0625rem;
    line-height: 1.5rem;
    letter-spacing: 0.05px;
  }
  &.paragraph-note {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.13px;
  }
  &.paragraph-footnote {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: -0.04px;
  }
  &.paragraph-label {
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 0.05px;
  }
}
.promo {
  &.promo-medium {
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 32px;
    letter-spacing: -0.4px;
  }
  &.promo-small {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.26px;
  }
}
.act {
  &.act-action {
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 24px;
    letter-spacing: -0.2px;
  }
  &.act-callout {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.15px;
  }
  &.act-footnote {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: -0.1px;
  }
  &.act-label {
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    letter-spacing: -0.05px;
  }
}