@import '../../../assets/styles/colors.scss';
@import '../../../assets/styles/mixins.scss';


.swiper {
  width: 100%;
  height: 105px;
  margin-bottom: 60px;
  z-index: 10;
  box-shadow: 0px 20px 75px -30px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  border-radius: 12px;

  animation: fadeIn 1s cubic-bezier(0.375, 0.885, 0.6, 1);
  -webkit-animation: fadeIn 1s cubic-bezier(0.375, 0.885, 0.6, 1);
  -moz-animation: fadeIn 1s cubic-bezier(0.375, 0.885, 0.6, 1);
  -o-animation: fadeIn 1s cubic-bezier(0.375, 0.885, 0.6, 1);
  -ms-animation: fadeIn 1s cubic-bezier(0.375, 0.885, 0.6, 1);
}

.swiper-slide {
  padding: 1.5rem 1.5rem;
}

.active {
  opacity: 1;
  visibility: visible;
}