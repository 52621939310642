.donate {
  background-color: #FAFAFA;

  main {
    padding-top: 65px;
    padding-bottom: 60px;

    @media (max-width: 575px) {
      padding-top: 10px;
      overflow: hidden;
    }
    .content-container {
      overflow: visible;
    }
  }

  .hero {
    display: grid;
    position: relative;
    grid-template-columns: [first] 470px [line2] auto [last];
    grid-template-rows: [start] 80px [row-1] 310px [row-2] 120px [end];
    grid-template-areas: 
    "header void"
    "description void"
    "cta void"
    "disclaimer void";
    justify-items: start;
    align-items: start;
    justify-content: start;
    margin-top: 0;
    width: 100%;
    justify-content: stretch;
    margin-bottom: 100px;
    padding-top: 20px;

    .donate-hero-animation {
      grid-area: void;
      position: absolute;
      top: 30px;
      right: -100px;
      height: 640px;
      width: 600px;

      div {
        margin: 0 !important;
        transform: translate(110px, 50px);
      }

      &:before {
        display: block;
        content: '';
        height: 640px;
        width: 600px;
        background-image: url('../../images/illustrations/donate/donate.png');
        background-position: top right;
        background-size: 620px auto;
        background-repeat: no-repeat;
        z-index: 0;
        top: 0px;
        right: 0;
        position: absolute;
      }
      &:after {
        display: block;
        content: '';
        height: 70px;
        width: 70px;
        margin-top: 20px;
        margin-bottom: 20px;
        background-image: url('../../images/illustrations/donate/flower.png');
        background-repeat: no-repeat;
        background-position: 0px center;
        background-size: contain;
        z-index: 3;
        top: 40px;
        right: 325px;
        position: absolute;
        animation: float 6s ease-in-out infinite;
      }

      @media (max-width: 1199px) {
        right: -50px;
        transform: scale(.95);
      }
      @media (max-width: 991px) {
        transform: scale(0.8);
        right: -50px;
        top: 0px;
      }
      @media (max-width: 767px) {
        left: 50%;
        right: auto;
        top: -100px;
        grid-area: unset;
        transform: scale(0.95) translate(-330px, 0);
      }
      @media (max-width: 575px) {
        left: 50%;
        right: auto;
        top: 0px;
        grid-area: unset;
        transform: scale(0.8) translate(-390px, 0);
      }
      @media (max-width: 450px) {
        left: 50%;
        right: auto;
        top: -20px;
        grid-area: unset;
        transform: scale(0.7) translate(-440px, 0);
      }
      @media (max-width: 399px) {
        left: 50%;
        right: auto;
        top: -50px;
        grid-area: unset;
        transform: scale(0.6) translate(-520px, 0);
      }
      @media (max-width: 349px) {
        left: 50%;
        right: auto;
        top: -90px;
        grid-area: unset;
        transform: scale(0.5) translate(-610px, 0);
      }
      // @media (max-width: 687px) {
      //   transform: translateX(calc(-170px)) translateY(-80px) scale(0.7);
      // }
      // @media (max-width: 627px) {
      //   transform: translateX(calc(-165px)) translateY(-80px) scale(0.7);
      // }
      // @media (max-width: 575px) {
      //   transform: translateX(calc(-170px)) translateY(70px) scale(0.6);
      // }
    }

    @media (max-width: 1199px) {
      background-size: 500px auto;
      grid-template-columns: [first] 400px [line2] auto [last];
      grid-template-rows: [start] 80px [row-1] 310px [row-2] 120px [end];
    }
    @media (max-width: 991px) {
      grid-template-columns: [first] 370px [line2] auto [last];
      grid-template-rows: [start] 80px [row-1] 340px [row-2] 120px [end];
    }
    @media (max-width: 767px) {
      background-size: 80vw auto;
      background-position: top center;
      padding-top: 430px;

    }
    @media (max-width: 575px) {
      background-image: none;
      padding-top: 0;
      display: flex;
      flex-direction: column;
      align-items: stretch;

      &:before {
        content: none
      }
    }

    h1 {
      grid-area: header;
      font-size: 2.25rem;
      line-height: 3rem;
      padding-top: 20px;

      @media (max-width: 575px) {
        margin-bottom: 20px;
        font-size: 2rem;
        line-height: 2.5rem;
      }
    }
    & > .page-description {
      grid-area: description;

      &:before {
        @media (max-width: 575px) {
          display: block;
          content: '';
          height: calc(3 * 105vw / 4);
          margin-top: 20px;
          margin-bottom: 20px;
          padding-bottom: 400px;
          background: none;
          background-repeat: no-repeat;
          background-position: 0px center;
          background-size: contain;
        }
        @media (max-width: 450px) {
          padding-bottom: 360px;
        }
        @media (max-width: 399px) {
          padding-bottom: 330px;
        }
        @media (max-width: 349px) {
          padding-bottom: 240px;
        }
      }
      .subtitle {
        max-width: 300px;
        margin-top: 30px;
      }
    }
    & > button {
      grid-area: what-is-staking;
      width: 170px;
      min-width: 170px;
      font-size: 15px;
      font-weight: 500;
      color: $surf-blue;
      outline: none !important;
      border: none !important;
      box-shadow: none !important;

      @media (max-width: 575px) {
        margin: 0px auto 30px;
      }
    }
    .buttons-container {
      grid-area: cta;
      display: flex;
      flex-direction: row;
      justify-content: stretch;
      align-items: center;
      height: 100px;

      @media (max-width: 575px) {
        margin-bottom: 1rem;
      }

      a {
        flex-grow: 1;
        display: none;

        @media (max-width: 767px) {
          width: 100%;
        }
        @media (max-width: 575px) {
          margin-bottom: 20px;
        }
        button {
          &.btn-xl {
            width: 170px;
            min-width: 170px;
            position: relative;
            @include transition(0.15s);

            &:after {
              content: '';
              position: absolute;
              top: 24px;
              opacity: 0;
              right: 36px;
              background-repeat: no-repeat;
              background-position: center -2px;
              width: 16px;
              display: block;
              height: 16px;
              margin-left: 8px;

              @include transition(0.15s);
              background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.75 8H13.25' stroke='%23FDFDFD' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M8 2.75L13.25 8L8 13.25' stroke='%23FDFDFD' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
              background-size: 18px 18px;
            }
            &:hover {
              padding-right: 2rem;
              @include transition(0.35s);
            }
            &:hover:after {
              @include transition(0.35s);
              transform: translateX(10px);
              opacity: 1;
            }
            @media (max-width: 575px) {
              width: 100%;
            }
          }
        }
      }
      .qr-code {
        grid-area: qr-code;
        flex-grow: 0;
        width: 100px;
        height: 100px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: .625rem;
        @include transition(0.1s);
        max-width: 110px;
        box-shadow: 0 0 0 1px white;
        border-radius: 12px;
        margin-left: -.6rem;
        position: relative;
        z-index: 1000;
        background-color: #ffffff;

        svg {
          width: 80px;
          height: 80px;
        }
        &:hover {
          transform: scale(2.5) translateX(.25rem);
          transform-origin: 0 center;
          @include transition(0.25s);
          box-shadow: 0 0.03rem 0 0.05rem rgba($surf-blue, 0.075), 0 30px 30px 0 rgba(#050520, 0.15);
        }
        &:first-of-type {
          @media (max-width: 419px) {
            grid-area: card-one;
          }
        }
        &:last-of-type {
          @media (max-width: 419px) {
            grid-area: card-two;
          }
        }
      }
      .promo {
        padding-left: 2rem;
        white-space: pre-line;

        @media (max-width: 399px) {
          padding-left: 1rem;
          white-space: normal;
        }
      }
    }
    .disclaimer {
      grid-area: disclaimer;
      color: $SURF_SECONDARY;
    }
  }
}

.donate.isMobile {
  .hero {
    .buttons-container {
      @media (max-width: 575px) {
        margin-bottom: 0;
      }
      a {
        display: block;
      }
      .qr-code, .promo {
        display: none;
      }
    }
  }
}

@keyframes float {
	0% {
		transform: translatey(0px);
	}
	50% {
		transform: translatey(-10px);
	}
	100% {
		transform: translatey(0px);
	}
}
