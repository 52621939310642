$surf-blue: #0083e0;
$surf-cobalt: #3888FF;
$surf-pink: #FF7B86;
$surf-yellow: #F1CD5E;
$surf-green: #88BF26;

$purple: #4963E6;

$main: $purple;

$SURF_WHITE: #FFFFFF;
$SURF_GRAY_BACKGROUND: #FAFAFC;
$SURF_GRAY_BACKGROUND_2: #F4F4F5;
$SURF_GRAY: #20262A;
$SURF_GRAY_2: #707376;
$SURF_SECONDARY: #707376;
$SURF_TERTIARY: #B6B8BA;

$dark: #1C2125;
$dark-alt: #20262A;
$dark-gray: #3B4043;
$gray: #676B6E;
$tertiary: #b6b8ba;
$gray-light: #8c8f91;
$gray-extralight: #ADB0B1;
$white: #ffffff;
$green-alt: #2FA851; //#36C05C;
$green: #36C05C;
$blue: #0083E0;
$red: #e22626;
$white: #FFFFFF;
$surf-gray-background: #fafafc;
$surf-gray-background-alt: #f4f4f5;
$surf-gray: #20262A;
$surf-gray-2: #707376;
$surf-gray-3: #6b707e;
$surf-gray-4: #a2a9b9;

// scss-docs-start gray-color-variables
$white:    #fff;
$gray-100: #fafafc;
$gray-200: #e9eaef;
$gray-300: #dedfe6;
$gray-400: #ced2da;
$gray-500: #adb3bd;
$gray-600: #6c717d;
$gray-700: #494d57;
$gray-800: #343840;
$gray-900: #212329;
$black:    #000;