@import '../colors.scss';
@import '../mixins.scss';

header {
  height: 72px;

  .promo {
    display: none;
  }

  &.active {
    nav.navbar {
      z-index: 2000;
      box-shadow: 0px -1px 1px rgba(32, 38, 42, 0.01), 0px 2px 2px rgba(134, 135, 160, 0.03), 0px 4px 4px rgba(134, 135, 160, 0.03), 0px 8px 8px rgba(134, 135, 160, 0.03);
             
      & {
        @include transition(0.5s);
        background: #FFFFFFd0;
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
        box-shadow: 0px -1px 1px rgba(32, 38, 42, 0.01), 0px 2px 2px rgba(134, 135, 160, 0.03), 0px 4px 4px rgba(134, 135, 160, 0.03), 0px 8px 8px rgba(134, 135, 160, 0.03);
      } 
    }
  }
  
  .navbar {
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 0;
    padding-right: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    @include transition(0.35s);
    box-shadow: 0px -1px 1px rgba(32, 38, 42, 0.00), 0px 2px 2px rgba(134, 135, 160, 0.00), 0px 4px 4px rgba(134, 135, 160, 0.00), 0px 8px 8px rgba(134, 135, 160, 0.00);
            
    &.fixed-shadow {
      @include transition(0.5s);
      background: #FFFFFFd0;
      -webkit-backdrop-filter: blur(10px);
      backdrop-filter: blur(10px);
      box-shadow: 0px -1px 1px rgba(32, 38, 42, 0.01), 0px 2px 2px rgba(134, 135, 160, 0.03), 0px 4px 4px rgba(134, 135, 160, 0.03), 0px 8px 8px rgba(134, 135, 160, 0.03);
    }  

    .navbar-brand {
      position: relative;
      z-index: 1000;
      padding-left: 1rem;
      padding-right: 1rem;

      @media (max-width: 991px) {
        flex-grow: 1;
        margin-right: 0;
      }

      .logo {
        font-size: 1rem;
        font-weight: 500;
        color: $SURF_GRAY;

        svg {
          vertical-align: middle;
          margin-right: 10px;
          margin-left: 4px;
        }
      }
    }
    .btn {
      border-radius: 20px;
      height: 40px;
      padding: 0.4rem 1.25rem;
      position: relative;
      z-index: 20;
      
      @media (max-width: 991px) {
        margin-right: 8px;
      }
    }
    .navbar-collapse {
      flex-grow: 0;
      margin-left: 1rem;
      margin-right: 1rem;
      
      @media (max-width: 991px) {
        margin-left: 0rem;
        margin-right: 0rem;
      }

      .navbar-nav {
        @include transition(0.15s);

        @media (max-width: 991px) {
          margin-top: 1px;
          position: relative;
          z-index: 1100;
          padding-left: 0.25rem;
          padding-right: 0.25rem;
          @include transition(0.35s);
        }
        & > * {
          margin-left: 15px;
        }
        a {
          &.nav-link {
            color: $SURF_GRAY;
            font-weight: 500;
            white-space: nowrap;

            @media (max-width: 991px) {
              height: 60px;
              line-height: 40px;
            }

            sup {
              margin-left: 4px;
              color: $surf-green;
              color: #2FA851;
            }
            
            &:hover, &.active {
              color: $surf-blue;

              sup {
                color: $surf-blue;
              }
            }
          }
        }
      }
    }
    .navbar-toggler {
      border: none;
      position: relative;
      width: 54px;
      height: 40px;
      z-index: 2;
      margin-right: 0.5rem;

      &, &:hover, &:focus, &:active {
        &, &:hover, &:focus, &:active {
          border: none;
          box-shadow: none;
          outline: none;
        }
      } 

      .navbar-toggler-icon {
        background-image: none;
        display: none;
      }

      &:before, &:after {
        content: '';
        height: 2px;
        width: 24px;
        background-color: $SURF_GRAY;
        position: absolute;
        @include transition(0.15s);
        left: 15px;
      }
      &:before {
        top: 15px;
      }
      &:after {
        top: 21px;
      }

      &:not(.collapsed) {
        &:before {
          transform: rotate(45deg) translateY(2px) translateX(2px);
        }
        &:after {
          transform: rotate(-45deg) translateY(-2px) translateX(2px);
        }
      }

      &:hover {
        &:before, &:after {
          background-color: $surf-blue;
          @include transition(0.35s);

        }
      }
    }
  }
}

.isMobile {
  header {
    
    .navbar {
      background: #FFFFFFd0;
      -webkit-backdrop-filter: blur(10px);
      backdrop-filter: blur(10px);
    }
  }  
}

.has-promo {

  header {
    height: 152px;
    
    .promo {
      background-color: #F6F6F6;
      display: flex;
      justify-content: center;
      position: fixed;
      z-index: 1100;
      top: 0;
      width: 100%;

      @media (max-width: 648px) {
        height: 12.362637vw;
      }
    }
    .navbar {
      top: 80px;

      @media (max-width: 648px) {
        top: 12.362637vw;
      }
    }
  }
}