@import '../colors.scss';
@import '../mixins.scss';

footer {
  border-top: 1px solid rgba($SURF_GRAY, 0.05);
  height: 72px;
  font-size: 0.925rem;
  @media (max-width: 991px) {
    min-height: 72px;
    height: auto;
  }

  .container-fluid {
    & {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      line-height: 70px;
    }
    &:first-child {
      @media (max-width: 991px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        line-height: 50px;
        padding-top: 1rem;
        padding-bottom: 1rem;
      }
    }
    &:last-child {
      background-color: $white;
      justify-content: center;
      line-height: 70px;

      @media (max-width: 399px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        line-height: 2rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
      }

      a {
        font-weight: 700;
        color: $surf-gray;
        margin-right: .125rem;
      }
    }
  }
  
  @media (max-width: 499px) {
    height: auto;
  }

  .footer-everscale {
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 70px;

      svg {
        width: 100px;
        vertical-align: middle;
      }
    }
  }

  .copyright {
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    @media (max-width: 500px) {
      text-align: center;
      flex-direction: column;
      align-items: center;
    }

    span, a {
      opacity: .4;
      color: $SURF_GRAY;
    }
    a {
      margin-right: 1.25rem;
      flex-grow: 0;
      flex-shrink: 0;
      display: inline-block;
      white-space: nowrap;
      width: min-content;

      @media (max-width: 500px) {
        margin-right: 0;
      }

      &:hover {
        opacity: 1;
        color: $surf-blue;
      }
    }
  }
  .nav {
    margin-top: 0;
    margin-bottom: 0;

    .nav-item {
      margin-right: 15px;
    }
    @media (max-width: 500px) {
      flex-direction: column;
      margin-bottom: 20px;

      .nav-item {
        margin-right: 15px;
        height: 40px;
        text-align: center;
      }
    }
  }
  .dod {
    color: $SURF_GRAY;
    opacity: .4;
    line-height: 3rem;
    display: inline-block;

    &:hover {
      opacity: 1;
      color: darken($surf-blue, 10%);
    }
  }
}