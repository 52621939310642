@import '../colors.scss';
@import '../mixins.scss';

.btn {
  height: 48px;
  padding: 0.4rem 0.75rem;
  font-weight: 500;
  border-radius: 12px;
  @include transition(0.35s);
}

.btn-primary {
  &, &:hover, &:focus, &:active {
    &, &:hover, &:focus, &:active {
      color: $white;
    }
  }
}

.btn-transparent {
  &, &:hover, &:focus, &:active {
    &, &:hover, &:focus, &:active {
      border-color: transparent;
      background-color: transparent;
      outline: none;
      color: $surf-blue;
      box-shadow: none;
    }
  }
}

.btn-black {
  & {
    &, &:focus, &:active {
      background-color: $surf-gray;
      color: $white;
      box-shadow: none;
    }
  }
  &:hover {
    color: $white;
    background-color: $dark-gray;
  }
  &:active {
    &:hover {
      color: $white;
      background-color: $dark-gray;
      box-shadow: 0 0 0 0.2rem rgba($dark-gray, 25%);
    }
  }
  &.active {
    &, &:hover, &:focus, &:active {
      background-color: $white;
      color: $dark-gray;
      box-shadow: none;
    }
  }
}

.btn-bordered {
  background-color: transparent;
  border-color: $blue !important;
  color: $blue;

  &:hover {
    background-color: rgba($blue, .1) !important;;
    border-color: desaturate(darken($blue, 10), 15);
    color: desaturate(darken($blue, 10), 0);
  }
  &:focus, &:active {
    &, &:hover, &:focus, &:active {
      background-color: transparent;
      border-color: desaturate(darken($blue, 10), 15);
    }
  }
  &, &:hover, &:focus, &:active {
    &:disabled {
      background-color: transparent;
      border-color: desaturate(darken($blue, 10), 15);
      pointer-events: none;
      opacity: 0.75;
    }
  }
  &.btn-black {
    border-color: $surf-gray !important;
    color: $surf-gray;

    & {
      &, &:focus, &:active {
        border-color: $surf-gray !important;
      }
    }
    &:hover {
      background-color: rgba($surf-gray, .1) !important;;
      border-color: desaturate(darken($surf-gray, 10), 15);
    }
    &:active {
      &:hover {
        color: $white;
        background-color: $dark-gray;
        box-shadow: 0 0 0 0.2rem rgba($dark-gray, 25%);
      }
    }
    &.active {
      &, &:hover, &:focus, &:active {
        background-color: $white;
        color: $dark-gray;
        box-shadow: none;
      }
    }
  }
}

.btn-icon, .btn-icon-after {
  padding-right: 2rem;
  &:after {
    content: '';
    background-repeat: no-repeat;
    background-position: center -2px;
    width: 16px;
    display: inline-block;
    vertical-align: middle;
    height: 16px;
    margin-left: 8px;
    @include transition(0.15s);
  }
}
.btn-icon-before {
  padding-right: 2rem;
  &:before {
    content: '';
    background-repeat: no-repeat;
    background-position: center -2px;
    width: 16px;
    display: inline-block;
    vertical-align: middle;
    height: 16px;
    margin-right: 10px;
    @include transition(0.15s);
  }
}
.btn-icon-arrow-right {
  &:after {
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.75 8H13.25' stroke='%23FDFDFD' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M8 2.75L13.25 8L8 13.25' stroke='%23FDFDFD' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  }
  &:hover:after {
    @include transition(0.35s);
    transform: translateX(3px);
  }
}
.btn-icon-arrow-up-right {
  &:after {
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.5 4.5L4.5 11.5' stroke='%2320262A' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M11.5 11.5V4.5H4.5' stroke='%2320262A' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  }
  &:hover:after, &:hover:before {
    @include transition(0.35s);
    transform: translateX(-3px);
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.5 4.5L4.5 11.5' stroke='%233888FF' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M11.5 11.5V4.5H4.5' stroke='%233888FF' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  }
}
.btn-icon-arrow-down {
  &:after, &:before {
    height: 24px;
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 3V21' stroke='%230073C4' stroke-width='1.75' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M19 14L12 21L5 14' stroke='%230073C4' stroke-width='1.75' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  }
  &:hover:after, &:hover:before {
    @include transition(0.35s);
    transform: translateY(2px);
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 3V21' stroke='%23006ba1' stroke-width='1.75' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M19 14L12 21L5 14' stroke='%23006ba1' stroke-width='1.75' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  }
}

.dropdown-toggle {
  &:after {
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3 6L8 11L13 6' stroke='%2320262A' stroke-width='1.6' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    content: '';
    background-repeat: no-repeat;
    background-position: center -2px;
    width: 16px;
    display: inline-block;
    vertical-align: middle;
    height: 16px;
    margin-left: 8px;
    @include transition(0.15s);
    border: none;
  }

  &:hover:after {
    @include transition(0.35s);
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3 6L8 11L13 6' stroke='%233888FF' stroke-width='1.6' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  }
}

.dropdown-menu {
  border-radius: 10px;
  border: none;
  overflow: hidden;
  padding: 0;
  box-shadow: 0 1px 2px 0 rgba(61,75,91,0.03),  0 2px 5px 0 rgba(61,75,91,0.05), 0 5px 12px 0 rgba(33,39,56,0.06), 0 15px 30px 0 rgba(33,39,56,0.1);

  .dropdown-item {
    padding: 0.5rem 1rem;

    &:first-child {
      padding-top: 0.75rem;
    }

    &:last-child {
      padding-bottom: 0.75rem;
    }
    
    &:hover {
      background-color: #F7F8FA;
    }
  }
}

.btn-sm {
  height: 40px;
  min-width: 0;
  letter-spacing: -.02rem;
  border-radius: 12px;
  font-size: .94rem;
  vertical-align: middle;
  line-height: 1.2rem;

  & + * {
    font-size: .94rem;
  }
}

.btn-lg {
  height: 56px;
  min-width: 132px;
  font-size: 1.075rem;
  letter-spacing: -.02rem;
  border-radius: 12px;
}

.btn-xl {
  height: 62px;
  min-width: 180px;
  font-size: 1.075rem;
  letter-spacing: -.02rem;
  border-radius: 12px;
}